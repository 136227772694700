import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["input", "terms"];

  connect() {
    if (!this.hasInputTarget) return;
  }

  showterms() {
    this.termsTarget.style.display = "block";
  }
}
