import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [
    "inputChoice",
    "envelope",
    "partner",
    "recipientFirstName",
    "recipientLastName",
    "address",
    "additionalAddressDetails",
    "zipcode",
    "city",
    "keysDescription",
    "connectParking",
    "connect",
    "submit",
    "personalInfoConsent",
  ];

  connect() {
    var currentTargetChecked = this.inputChoiceTargets.find((target) => target.checked);
    if (currentTargetChecked != null) {
      this.changesForValue(currentTargetChecked.value);
    }
  }

  changedChoice(e) {
    var value = e.currentTarget.value;
    this.changesForValue(value);
  }

  changesForValue(value) {
    if (value == "yespark_envelope") {
      this.envelopeTarget.classList.remove("d-none");
      this.partnerTarget.classList.add("d-none");
      this.connectTarget.classList.add("d-none");
      this.submitTarget.removeAttribute("disabled");
      this.enableEnvelopeFields();
      this.disableConnectFields();
    } else if (value == "partner") {
      this.envelopeTarget.classList.add("d-none");
      this.partnerTarget.classList.remove("d-none");
      this.connectTarget.classList.add("d-none");
      this.submitTarget.removeAttribute("disabled");
      this.disableEnvelopeFields();
      this.disableConnectFields();
    } else if (value == "connect") {
      this.envelopeTarget.classList.add("d-none");
      this.partnerTarget.classList.add("d-none");
      this.connectTarget.classList.remove("d-none");
      this.enableConnectFields();
      this.disableEnvelopeFields();
      this.toggleSubmitButton();
    }
  }

  disableEnvelopeFields() {
    this.recipientFirstNameTarget.disabled = true;
    this.recipientLastNameTarget.disabled = true;
    this.addressTarget.disabled = true;
    this.additionalAddressDetailsTarget.disabled = true;
    this.zipcodeTarget.disabled = true;
    this.cityTarget.disabled = true;
    this.keysDescriptionTarget.disabled = true;
    this.personalInfoConsentTarget.disabled = true;
  }

  enableEnvelopeFields() {
    this.recipientFirstNameTarget.disabled = false;
    this.recipientLastNameTarget.disabled = false;
    this.addressTarget.disabled = false;
    this.additionalAddressDetailsTarget.disabled = false;
    this.zipcodeTarget.disabled = false;
    this.cityTarget.disabled = false;
    this.keysDescriptionTarget.disabled = false;
    this.personalInfoConsentTarget.disabled = false;
  }

  disableConnectFields() {
    this.connectParkingTargets.forEach((radioButton) => {
      radioButton.disabled = true;
    });
  }

  enableConnectFields() {
    this.connectParkingTargets.forEach((radioButton) => {
      radioButton.disabled = false;
    });
  }

  toggleSubmitButton() {
    var isAnyRadioButtonChecked = this.connectParkingTargets.some((radioButton) => radioButton.checked);
    if (isAnyRadioButtonChecked) {
      this.submitTarget.removeAttribute("disabled");
    } else {
      this.submitTarget.setAttribute("disabled", "true");
      this.submitTarget.innerText = "Terminer";
    }
  }
}
