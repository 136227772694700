import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  onSubmit(e) {
    e.preventDefault();
    this.dispatchEventFiltersSubmitted();
    if (this.element) {
      bootstrap.Modal.getOrCreateInstance(this.element).hide();
    }
  }

  onReset(e) {
    e.preventDefault();
    this.dispatchEventFiltersReset();
    if (this.element) {
      bootstrap.Modal.getOrCreateInstance(this.element).hide();
    }
  }

  dispatchEventFiltersChanged() {
    window.dispatchEvent(new CustomEvent("filters-changed", { bubbles: true }));
  }

  dispatchEventFiltersSubmitted() {
    window.dispatchEvent(new CustomEvent("filters-submitted", { bubbles: true }));
  }

  dispatchEventFiltersReset() {
    window.dispatchEvent(new CustomEvent("filters-reset", { bubbles: true }));
  }
}
