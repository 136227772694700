import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["withCommitment", "withoutCommitment", "input"];

  connect() {
    this.setDataEventAction();
    this.displayOrHide(this.inputTarget.value);
  }

  toggleVisibility(event) {
    this.displayOrHide(event.target.value);
  }

  displayOrHide(value) {
    if (value == "true") {
      this.withCommitmentTargets.forEach((target) => {
        target.classList.remove("d-none");
      });
      this.withoutCommitmentTargets.forEach((target) => {
        target.classList.add("d-none");
      });
    } else {
      this.withCommitmentTargets.forEach((target) => {
        target.classList.add("d-none");
      });
      this.withoutCommitmentTargets.forEach((target) => {
        target.classList.remove("d-none");
      });
    }
  }

  setDataEventAction() {
    document.querySelectorAll("#annual_commitment_true").forEach((input) => {
      input.setAttribute("data-event-action", "annual-commitment");
    });
    document.querySelectorAll("#annual_commitment_false").forEach((input) => {
      input.setAttribute("data-event-action", "no-commitment");
    });
  }
}
