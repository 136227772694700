import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = {
    priceKwh: Number,
    dataset: Array,
  };

  static get targets() {
    return ["vehicle", "usecase", "distance", "cost", "energy"];
  }

  onSubmit(event) {
    event.preventDefault();
    event.stopPropagation();

    const carData = this.datasetValue.find((data) => data.name == this.vehicleTarget.value);
    const energy = (carData.usecases[this.usecaseTarget.value] / 100) * this.distanceTarget.value;
    const cost = energy * this.priceKwhValue;

    this.energyTarget.innerText = parseInt(energy, 10);
    this.costTarget.innerText = parseInt(cost, 10);

    return false;
  }
}
