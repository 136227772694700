import { Application } from "@hotwired/stimulus";

const application = Application.start();

// propagate errors that happen inside Stimulus controllers
if (typeof window.__TEST_ENVIRONMENT__ !== "undefined" && window.__TEST_ENVIRONMENT__) {
  application.handleError = (error, _message, _detail) => {
    throw error;
  };
}

// Configure Stimulus development experience
application.debug = false;
window.Stimulus = application;

/**
 * Attaches a reference of a Stimulus controller to its element for easy access across the application.
 * This helps in managing state or interactions between different controllers.
 *
 * @param {Controller} stimulusController - The Stimulus controller instance to be attached.
 * @param {boolean} debug - Optional parameter to log the normalized identifier for debugging.
 */
window.hangReferenceStimulusController = (stimulusController, debug = false) => {
  if (!stimulusController.identifier) {
    console.error("Stimulus controller is missing an identifier.");
    return;
  }

  // Normalize the identifier to camelCase to be used as a property name.
  const normalizedIdentifier = stimulusController.identifier
    .split(/--|-/)
    .map((part, index) => (index === 0 ? part : part.charAt(0).toUpperCase() + part.slice(1).toLowerCase()))
    .join("");

  // Optionally log the normalized identifier if debugging is enabled.
  if (debug) {
    console.log(`hangReferenceStimulusController - Normalized Identifier: ${normalizedIdentifier}`);
  }

  // Attach the controller reference to its element using the normalized identifier.
  stimulusController.element[normalizedIdentifier] = stimulusController;
};

export { application };
