import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  triggerSubscriptions() {
    document.getElementById("navlink-subscriptions").click();
  }

  triggerBookings() {
    document.getElementById("navlink-bookings").click();
  }
}
