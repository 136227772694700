import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  submit() {
    if (dataLayer !== undefined) {
      dataLayer.push({
        event: "addToCart",
        event_category: "ecommerce",
        event_action: "addToCart",
        event_label: window.parking_name,
        parkings: window.parkings_array,
      });
    }
  }
}
