import { Controller } from "@hotwired/stimulus";
import { useResize } from "stimulus-use";

export default class extends Controller {
  static values = {
    mapboxDistJsUrl: String,
    mapboxDistJsIntegrity: String,
    mapboxDistCssUrl: String,
    mapboxToken: String,
    mapboxStyle: String,
    environment: String,
    defaultMapFlySpeed: {
      type: Number,
      default: 3000,
    },
    initialZoom: {
      type: Number,
      default: 5.25,
    },
    minZoom: {
      type: Number,
      default: 5,
    },
    maxZoom: {
      type: Number,
      default: 16.4,
    },
    proRequestsUrl: {
      type: String,
      default: "",
    },
  };

  static targets = ["canvas", "form", "showRequestsPro", "hideRequestsPro"];

  initialize() {
    this.addressMarker = null;
    this.parkingsMarkers = [];
    this.proRequestsMarkers = [];
    this.queueEventsParkingsUpdated = false;
    this.mapLoaded = false;
  }

  async connect() {
    window.hangReferenceStimulusController(this);
    Promise.all([this.loadMapboxStylesheet(), this.loadMapboxJavascript()])
      .then(() => {
        this.initializeMapbox();
      })
      .catch((error) => {
        alert("Une erreur est survenue lors du chargement de la carte.");
        console.error(error);
      });
  }

  disconnect() {
    if (this.__map != undefined) {
      this.__map.remove();
      this.__map = undefined;
    }
  }

  loadMapboxJavascript() {
    return new Promise((resolve, reject) => {
      const script = document.createElement("script");
      script.src = this.mapboxDistJsUrlValue;
      script.dataset.turboTrack = "reload";
      script.type = "module";
      script.dataset.turboEval = "false";
      script.async = "async";
      script.defer = "defer";
      script.integrity = this.mapboxDistJsIntegrityValue;
      script.crossorigin = "anonymous";
      script.referrerpolicy = "no-referrer";
      script.onload = resolve;
      script.onerror = reject;
      document.body.appendChild(script);
    });
  }

  loadMapboxStylesheet() {
    return new Promise((resolve, reject) => {
      const link = document.createElement("link");
      link.rel = "preload";
      link.as = "style";
      link.crossorigin = "anonymous";
      link.referrerpolicy = "no-referrer";
      link.href = this.mapboxDistCssUrlValue;
      link.onload = () => {
        this.onload = null;
        link.rel = "stylesheet";
        resolve();
      };
      link.onerror = reject;
      document.body.appendChild(link);
      if (link.rel !== "preload") link.rel = "stylesheet";
    });
  }

  async initializeMapbox() {
    if (typeof mapboxgl === "undefined") {
      console.error("Mapbox GL JS is not loaded");
      return;
    }

    mapboxgl.accessToken = this.mapboxTokenValue;

    this.centerLng = parseFloat(this.formTarget.querySelector("#center_lng").value);
    this.centerLat = parseFloat(this.formTarget.querySelector("#center_lat").value);

    this.__map = new mapboxgl.Map({
      container: this.canvasTarget,
      style: this.environmentValue == "test" ? "mapbox://styles/mapbox/streets-v12" : this.mapboxStyleValue,
      center: [this.centerLng, this.centerLat],
      zoom: this.initialZoomValue,
      minZoom: this.minZoomValue,
      maxZoom: this.maxZoomValue,
      respectPrefersReducedMotion: false,
      testMode: this.environmentValue == "test",
    });

    this.mapLoaded = false;

    this.__map.on("load", () => {
      this.mapLoaded = true;
    });

    this.__map.once("styledata", function () {
      const lang = window.locale;
      this.setLayoutProperty("country-label", "text-field", ["get", "name_" + lang]);
      this.setLayoutProperty("state-label", "text-field", ["get", "name_" + lang]);
      this.setLayoutProperty("airport-label", "text-field", ["get", "name_" + lang]);
      this.setLayoutProperty("poi-label", "text-field", ["get", "name_" + lang]);
      this.setLayoutProperty("settlement-subdivision-label", "text-field", ["get", "name_" + lang]);
      this.setLayoutProperty("water-point-label", "text-field", ["get", "name_" + lang]);
      this.setLayoutProperty("water-line-label", "text-field", ["get", "name_" + lang]);
      // this.getStyle().layers.forEach((l) => {
      //   if (l.type == "symbol" || l.id.indexOf("road") != -1 || l.id.indexOf("boundary") != -1) {
      //     this.setLayoutProperty(l.id, "visibility", "none");
      //   }
      // });
    });

    this.__map.addControl(new mapboxgl.NavigationControl({ showCompass: false }));
    this.__map.dragRotate.disable();
    this.__map.touchZoomRotate.disableRotation();

    this.__map.once("idle", async (e) => {
      this.canvasTarget.style.opacity = "1";
      if (this.queueEventsParkingsUpdated) this.updateMap();
    });

    this.__map.on("dragend", (e) => {
      this.formTarget.querySelector("#moved_map").value = true;
      this.setFormSearchBoundsFields(this.__map);
      this.setFormSearchLatitudeLongitudeFields("", "");
      this.callbackActionsOnMap(e);
    });

    this.__map.on("zoomend", (e) => {
      if (this.preciseAddressSearched()) this.changeZoomInUrl(e.target.getZoom());

      this.setFormSearchBoundsFields(this.__map);

      if (e.originalEvent && (e.originalEvent.type == "wheel" || e.originalEvent.type == "click")) {
        this.formTarget.querySelector("#moved_map").value = true;
        this.setFormSearchLatitudeLongitudeFields("", "");
        this.callbackActionsOnMap(e);
      }
    });

    this.__map.once("render", () => {
      this.__map.resize();
    });

    useResize(this);
  }

  resize({ height, width }) {
    if (this.__map) this.__map.resize();
  }

  async resizeMap() {
    if (this.__map) await this.__map.resize();
  }

  isSearchPage() {
    return window.location && window.location.pathname && window.location.pathname.startsWith("/search");
  }

  changeZoomInUrl(zoom) {
    if (!this.isSearchPage()) return;
    var urlObject = new URL(window.location);
    var urlParams = urlObject.searchParams;
    urlParams.set("zoom", zoom.toPrecision(4));
    window.history.pushState(null, document.title, urlObject);
  }

  setFormSearchBoundsFields(map) {
    const currentBounds = map.getBounds();
    var southWestCoords = currentBounds.getSouthWest();
    var northEastCoords = currentBounds.getNorthEast();
    var centerCoords = currentBounds.getCenter();
    this.formTarget.querySelector("#sw_lat").value = southWestCoords.lat;
    this.formTarget.querySelector("#sw_lng").value = southWestCoords.lng;
    this.formTarget.querySelector("#ne_lat").value = northEastCoords.lat;
    this.formTarget.querySelector("#ne_lng").value = northEastCoords.lng;
    this.formTarget.querySelector("#center_lat").value = centerCoords.lat;
    this.formTarget.querySelector("#center_lng").value = centerCoords.lng;
  }

  setFormSearchLatitudeLongitudeFields(latitude, longitude) {
    this.formTarget.querySelector("#latitude").value = latitude;
    this.formTarget.querySelector("#longitude").value = longitude;
  }

  managerSearchAddressMarker() {
    const addressLat = this.formTarget.querySelector("#latitude").value;
    const addressLng = this.formTarget.querySelector("#longitude").value;

    if (
      typeof addressLat != "undefined" &&
      typeof addressLng != "undefined" &&
      addressLat != null &&
      addressLng != null
    ) {
      if (this.addressMarker != null) {
        if (addressLng != "" && addressLat != "") {
          if (this.addressMarker._lngLat.lng != addressLng || this.addressMarker._lngLat.lat != addressLat) {
            this.addressMarker.remove();
            if (this.preciseAddressSearched() || this.buttonLocateMeUsed()) {
              this.addressMarker = new mapboxgl.Marker().setLngLat([addressLng, addressLat]).addTo(this.__map);
            }
          }
        }
      } else if (this.preciseAddressSearched() || this.buttonLocateMeUsed()) {
        this.addressMarker = new mapboxgl.Marker().setLngLat([addressLng, addressLat]).addTo(this.__map);
      }
    }
  }

  preciseAddressSearched() {
    return this.formTarget.querySelector("#address").value !== "";
  }

  buttonLocateMeUsed() {
    return this.formTarget.querySelector("#locate").value == "true";
  }

  getQueryType() {
    return this.formTarget.querySelector("#query_type").value;
  }

  getMapZoomFromQueryType() {
    const queryType = this.getQueryType();
    if (queryType == "address") {
      return this.maxZoomValue;
    } else if (queryType == "suburb") {
      return 13;
    } else if (queryType == "city") {
      return 11;
    } else if (queryType == "department") {
      return 10;
    } else if (queryType == "region") {
      return 6.7;
    } else if (queryType == "country") {
      return 5;
    } else {
      return this.initialZoomValue;
    }
  }

  addParkingsMarkersFromParkingCards(parkingCards) {
    var parkingsMarkersToAdd = [];

    parkingCards.forEach((parkingCard) => {
      var parkingCardPreview = parkingCard.classList.contains("parking-card-preview");
      var popup;
      if (window.innerWidth < 992) {
        const card_clone = parkingCard;
        card_clone.setAttribute("data-event-action", "search_map_card");
        card_clone.querySelector(".parking-name").classList.remove("ds-text-truncate-line-2");
        card_clone.querySelector(".parking-name").classList.add("ds-text-truncate-line-1");

        popup = new mapboxgl.Popup();
        popup.setHTML(
          `<div class='parking-row-container'><div class='parking-row parking-row-as-popup'>${card_clone.innerHTML}</div></div>`,
        );
      } else {
        popup = null;
      }

      const customPin = document.createElement("div");

      var parkingStatus = null;

      if (!parkingCardPreview) {
        if (parkingCard.querySelector(".status-unavailable")) {
          parkingStatus = "unavailable";
        } else if (parkingCard.querySelector(".js-status-rented")) {
          parkingStatus = "rented";
        } else {
          parkingStatus = "available";
        }
      }

      var parkingId = parkingCard.getAttribute("data-parking-id");

      customPin.className = `marker marker-with-border${parkingStatus ? ` marker-${parkingStatus}` : ""}`;
      customPin.id = `marker-${parkingId}`;
      customPin.setAttribute("data-parking-id", `${parkingId}`);
      const elSpan = document.createElement("span");
      elSpan.className = `pin${parkingStatus ? ` pin-${parkingStatus}` : ""}`;
      let spanHtml = "";
      if (!parkingCardPreview) {
        var borneStatusIcon = parkingCard.querySelector(".parking-charging-station-infos--icon");
        var lookingForBorne = parkingCard.getAttribute("data-parking-recharge-filtered") == "true";
        var prioMarketIcon = parkingCard.querySelector(".prio-market-icon");
        if (prioMarketIcon != null && borneStatusIcon == null) {
          customPin.setAttribute("data-event-action", "pin_click_prio_market");
          prioMarketIcon.style.display = "block !important";
          spanHtml += prioMarketIcon.outerHTML;
        } else {
          customPin.setAttribute("data-event-action", "pin_click");
          if (borneStatusIcon != null && lookingForBorne) spanHtml += borneStatusIcon.outerHTML;
        }
        var priceText = parkingCard.querySelector(".parking-price-value");
        spanHtml += priceText.outerHTML.replace(" €", "€").replace("ds-text-title-sm", "ds-text-title-xs");
        const nbAvailableSpotsElement = parkingCard.querySelector(".js-parking-nb-available-spots");
        if (nbAvailableSpotsElement != null) {
          var nbAvailableSpots = nbAvailableSpotsElement.dataset["nbAvailableSpots"];
          if (nbAvailableSpots != null) {
            nbAvailableSpots = parseInt(nbAvailableSpots, 10);
            if (nbAvailableSpots != null) {
              spanHtml += " (" + nbAvailableSpots + ")";
            }
          }
        }
      } else {
        spanHtml = "...";
      }

      elSpan.innerHTML = spanHtml;
      customPin.appendChild(elSpan);

      parkingsMarkersToAdd.unshift({
        customPin: customPin,
        lng: parkingCard.getAttribute("data-lng"),
        lat: parkingCard.getAttribute("data-lat"),
        popup: popup,
      });
    });

    var parkingsMarkers = [];
    parkingsMarkersToAdd.forEach((data) => {
      var marker = new mapboxgl.Marker(data["customPin"])
        .setLngLat([data["lng"], data["lat"]])
        .setPopup(data["popup"])
        .addTo(this.__map);
      marker.getElement().addEventListener("click", this.onClickMarker.bind(this));
      parkingsMarkers.push(marker);
    });

    return parkingsMarkers;
  }

  onClickMarker(e) {
    document.querySelectorAll(".search-list-grid .parking-row[data-parking-id]").forEach((el) => {
      el.classList.remove("parking-row--focus");
      el.classList.add("parking-row--unfocus");
    });
    document.querySelectorAll(".marker-focus").forEach((el) => {
      el.classList.remove("marker-focus");
    });
    let marker = e.currentTarget;
    marker.classList.add("marker-focus");
    const parkingCardSidebar = document.querySelector(
      ".search-list-grid .parking-row[data-parking-id='" + marker.dataset.parkingId + "']",
    );
    parkingCardSidebar.classList.add("parking-row--focus");
    parkingCardSidebar.classList.remove("parking-row--unfocus");

    const searchListContainer = document.querySelector(".search-list-container");
    const searchListContainerRect = searchListContainer.getBoundingClientRect();
    const parkingCardSidebarRect = parkingCardSidebar.getBoundingClientRect();
    const scrollToPosition = searchListContainer.scrollTop + (parkingCardSidebarRect.top - searchListContainerRect.top);

    searchListContainer.scrollTo({
      top: scrollToPosition,
      behavior: "smooth",
    });
  }

  removeParkingsMarkers() {
    for (let marker of Array.from(this.parkingsMarkers)) marker.remove();
    this.parkingsMarkers = [];
  }

  removeProRequestsMarkers() {
    for (let marker of Array.from(this.proRequestsMarkers)) marker.remove();
    this.proRequestsMarkers = [];
  }

  async newSearchResults() {
    await this.updateMap();
  }

  fitBounds(queryType) {
    return queryType != "country" && queryType != "region" && queryType != "department";
  }

  async updateMap() {
    if (!this.__map) {
      this.queueEventsParkingsUpdated = true;
      return;
    }

    this.queueEventsParkingsUpdated = false;

    this.managerSearchAddressMarker();
    this.removeParkingsMarkers();

    var movedMap = this.formTarget.querySelector("#moved_map").value == "true";
    var parkingCards = document.querySelectorAll(".search-list-content .js_parking_card_map");

    if (parkingCards && parkingCards.length > 0) {
      this.parkingsMarkers = this.addParkingsMarkersFromParkingCards(parkingCards);
    }

    var queryType = this.getQueryType();
    if (this.buttonLocateMeUsed() || (movedMap == false && (this.preciseAddressSearched() || queryType == "address"))) {
      if (this.parkingsMarkers.length > 0 && this.fitBounds(queryType)) {
        const boundsBox = new mapboxgl.LngLatBounds();
        for (let marker of Array.from(this.parkingsMarkers)) boundsBox.extend(marker.getLngLat());
        this.__map.fitBounds(boundsBox, {
          maxZoom: this.maxZoomValue,
          essential: true,
          padding: 80,
        });
      } else {
        this.__map.flyTo({
          center: [
            this.formTarget.querySelector("#center_lng").value != ""
              ? this.formTarget.querySelector("#center_lng").value
              : this.formTarget.querySelector("#longitude").value,
            this.formTarget.querySelector("#center_lat").value != ""
              ? this.formTarget.querySelector("#center_lat").value
              : this.formTarget.querySelector("#latitude").value,
          ],
          essential: true,
          zoom: this.getMapZoomFromQueryType(),
        });
      }
      // const boundsBox = new mapboxgl.LngLatBounds();
      // const gpsLng = this.centerLng;
      // const gpsLat = this.centerLat;
      // const gpsMapboxLngLat = new mapboxgl.LngLat(gpsLng, gpsLat);
      // for (let marker of Array.from(this.parkingsMarkers)) boundsBox.extend(marker.getLngLat());
      // boundsBox.extend(gpsMapboxLngLat);
      // this.__map.fitBounds(boundsBox, {
      //   maxZoom: this.getMapZoomFromQueryType(),
      //   essential: true,
      //   padding: 40,
      // });
    }
  }

  callbackActionsOnMap(e) {
    if (window.innerWidth >= 992) {
      this.dispatchEventMapMoved();
    } else {
      document.querySelector(".search-toggle-search-here").classList.add("show");
    }
  }

  dispatchEventMapMoved() {
    window.dispatchEvent(new CustomEvent("map-moved", { bubbles: true }));
  }

  async fetchData(url) {
    try {
      const response = await fetch(url);
      if (!response.ok) throw new Error(`Error fetching data from ${url}: ${response.statusText}`);
      const jsonData = await response.json();
      return jsonData;
    } catch (error) {
      console.error("Error fetching JSON data:", error);
    }
  }

  async refreshProRequestsToTheMap({ params: { url } }) {
    if (this.hasShowRequestsProTarget) {
      this.showRequestsProTarget.classList.add("d-none");
      if (this.hasHideRequestsProTarget) {
        this.hideRequestsProTarget.classList.remove("d-none");
      }
    }

    this.removeProRequestsMarkers();

    const jsonData = await this.fetchData(`${url}.json`);
    this.addProRequestsMarkersFromJsonData(url, jsonData);
  }

  async hideProRequestsToTheMap({ params: { url } }) {
    if (this.hasShowRequestsProTarget) {
      this.showRequestsProTarget.classList.remove("d-none");
      if (this.hasHideRequestsProTarget) {
        this.hideRequestsProTarget.classList.add("d-none");
      }
    }

    this.removeProRequestsMarkers();
  }

  addProRequestsMarkersFromJsonData(url, jsonData) {
    jsonData.forEach((proRequest) => {
      const linkElement = document.createElement("a");
      linkElement.className = `pin pin-pro-request`;
      linkElement.href = `${url}/${proRequest.id}`;
      linkElement.setAttribute("data-turbo", true);
      linkElement.setAttribute("data-turbo-stream", true);
      linkElement.innerHTML = proRequest.spot_count;
      const customPin = document.createElement("div");
      customPin.className = `marker marker-pro-request`;
      customPin.appendChild(linkElement);
      const coords = [proRequest.lng, proRequest.lat];
      var marker = new mapboxgl.Marker(customPin).setLngLat(this.addSmallOffsetInMeters(coords, 30)).addTo(this.__map);
      this.proRequestsMarkers.push(marker);
    });
  }

  addSmallOffsetInMeters(coordinates, meters) {
    const latOffset = meters / 111139;
    const lngOffset = (meters / 111320) * Math.cos((Math.PI / 180) * coordinates[1]);

    const newCoordinates = [
      coordinates[0] + (Math.random() * 2 * lngOffset - lngOffset), // 1 degree of latitude is approximately 111,139 meters
      coordinates[1] + (Math.random() * 2 * latOffset - latOffset), // 1 degree of longitude is approximately 111,320 meters * cos(latitude)
    ];
    return newCoordinates;
  }
}
