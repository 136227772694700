import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["button", "text"];

  static values = {
    number: String,
  };

  connect() {
    if (!this.hasButtonTarget) return;
  }

  click() {
    this.buttonTarget.remove();
    this.textTarget.textContent = this.numberValue + ".";
  }
}
