import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = {
    textToCopy: String,
  };

  copyToClipboard() {
    navigator.clipboard.writeText(this.textToCopyValue);
    this.element.title = "Copié ✅";
    this.element.classList.add("text-success");
    this.element.classList.remove("text-cyan");
    let icon = this.element.querySelector("i");
    icon.classList.remove("fa-clone");
    icon.classList.remove("fas");
    icon.classList.add("far");
    icon.classList.add("fa-square-check");
  }
}
