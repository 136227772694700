import { Controller } from "@hotwired/stimulus";
import flatpickr from "flatpickr";
import { French } from "flatpickr/dist/l10n/fr.js";
import { Italian } from "flatpickr/dist/l10n/it.js";
import { Dutch } from "flatpickr/dist/l10n/nl.js";

const TLD_TO_LOCALE = {
  uk: "en",
  localhost: "fr",
};

const LOCALE_TO_FLATPICKER_LOCALE = {
  fr: French,
  it: Italian,
  nl: Dutch,
};

export default class extends Controller {
  static values = {
    clickOpens: {
      type: Boolean,
      default: true,
    },
    enableTime: {
      type: Boolean,
      default: false,
    },
    minDate: {
      type: String,
      default: "",
    },
    maxDate: {
      type: String,
      default: "",
    },
    defaultDate: {
      type: String,
      default: "",
    },
    altInputClass: {
      type: String,
      default: "",
    },
  };

  connect() {
    if (this.element._flatpickr === undefined) {
      var options = this.getFlatpickrOptions();
      flatpickr(this.element, options);
      if (this.element._flatpickr !== undefined) {
        this.element._flatpickr.altInput.addEventListener("focus", this.openCalendar.bind(this));
      }
    }
  }

  disconnect() {
    if (this.element._flatpickr !== undefined) {
      const value = this.element.value;
      this.element._flatpickr.destroy();
      this.element._flatpickr = undefined;
      this.element.value = value;
    }
  }

  openCalendar() {
    if (!this.clickOpensValue && this.element._flatpickr !== undefined) {
      this.element._flatpickr.open();
    }
  }

  closeCalendar() {
    if (this.element._flatpickr !== undefined) {
      this.element._flatpickr.close();
    }
  }

  onPageClick(e) {
    if (
      !e.target ||
      this.element == e.target ||
      this.element._flatpickr === undefined ||
      !this.element._flatpickr.isOpen ||
      e.target.classList?.contains("search-box--item") ||
      e.target.closest(".search-box--item") != null ||
      this.element.contains(e.target) ||
      this.element._flatpickr.altInput.contains(e.target) ||
      this.element._flatpickr.calendarContainer.contains(e.target)
    )
      return;
    this.closeCalendar();
  }

  getFlatpickrOptions() {
    const locale = this.getFlatpickerLocale();
    return {
      ignoredFocusElements: [document.body],
      clickOpens: this.clickOpensValue,
      closeOnSelect: true,
      minuteIncrement: 1,
      disableMobile: true,
      allowInput: true,
      altInput: true,
      altInputClass: this.hasAltInputClassValue ? this.altInputClassValue : "",
      altFormat: this.enableTimeValue ? "d/m/Y H:i" : "d/m/Y",
      dateFormat: this.enableTimeValue ? "Y-m-d H:i" : "Y-m-d",
      enableTime: this.enableTimeValue,
      ...(locale && { locale }),
      ...(this.minDateValue.length > 0 && { minDate: this.minDateValue }),
      ...(this.maxDateValue.length > 0 && { maxDate: this.maxDateValue }),
      ...(this.defaultDateValue.length > 0 && { defaultDate: this.defaultDateValue }),
      onClose: (_selectedDates, _dateStr, instance) => {
        this.setDateFromInput(instance);
      },
      onOpen: (_selectedDates, _dateStr, instance) => {
        this.setDateFromInput(instance);
      },
    };
  }

  setDateFromInput(instance) {
    if (!instance.input.value) instance.setDate(null);

    if (/^\d{4}-\d{2}-\d{2}$/.test(instance.input.value)) {
      var date = new Date(instance.input.value);
      if (date.getTime()) instance.setDate(instance.input.value);
    }
  }

  getFlatpickerLocale() {
    return LOCALE_TO_FLATPICKER_LOCALE[window.locale || this.getLocaleFromLtd()] || null;
  }

  getLocaleFromLtd() {
    const tld = window.location.hostname.split(".").pop();
    return TLD_TO_LOCALE[tld] || tld;
  }
}
