import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  checkShortTermInput() {
    document.getElementById("search_show_short_term").checked = true;
    this.showBookingBanner();
  }

  uncheckShortTermInput() {
    document.getElementById("search_show_short_term").checked = false;
    this.hideBookingBanner();
  }

  hideBookingBanner() {
    let defaultBanner = document.getElementById("default-banner");
    let bookingBanner = document.getElementById("booking-banner");
    let homepageAdvantages = document.getElementById("homepage-advantages");

    if (defaultBanner && bookingBanner) {
      defaultBanner.classList.remove("d-none");
      bookingBanner.classList.add("d-none");
    }
  }

  showBookingBanner() {
    let defaultBanner = document.getElementById("default-banner");
    let bookingBanner = document.getElementById("booking-banner");
    let homepageAdvantages = document.getElementById("homepage-advantages");

    if (defaultBanner && bookingBanner) {
      defaultBanner.classList.add("d-none");
      bookingBanner.classList.remove("d-none");
    }
  }
}
