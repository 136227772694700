import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = {
    anchor: String,
    scrollOnConnect: {
      type: Boolean,
      default: false,
    },
    disableClickBinding: {
      type: Boolean,
      default: false,
    },
  };

  connect() {
    if (this.scrollOnConnectValue) {
      this.scrollToAnchor();
    } else if (!this.disableClickBindingValue) {
      var dataAction = this.element.getAttribute("data-action");
      if (dataAction != null) {
        this.element.setAttribute("data-action", dataAction + " click->widgets--scroll-to#scrollToAnchor");
      } else {
        this.element.setAttribute("data-action", "click->widgets--scroll-to#scrollToAnchor");
      }
    }
  }

  scrollToAnchor() {
    document.querySelectorAll("[data-controller*='widgets--scroll-to'].active").forEach((item) => {
      item.classList.remove("active");
    });
    this.element.classList.add("active");
    var scrollToElement = document.getElementById(this.anchorValue);

    if (scrollToElement) {
      var headerOffset = 0;
      var header = document.querySelector("header.header");
      if (header) {
        var style = getComputedStyle(header);
        var position = style.position;
        if (position === "fixed" || position === "sticky") {
          headerOffset = header.getBoundingClientRect().height;
        }
      }

      const scrollTo = () => {
        var elementPosition = scrollToElement.getBoundingClientRect().top;
        var offsetPosition = elementPosition + window.scrollY - headerOffset;
        window.scrollTo({
          top: offsetPosition,
          behavior: "smooth",
        });
      };

      // Function to handle debounce
      let timeout;
      const handleScroll = () => {
        clearTimeout(timeout);
        timeout = setTimeout(() => {
          scrollTo(); // Recalculate and scroll again if needed
          window.removeEventListener("scroll", handleScroll);
        }, 150); // Delay of 150 milliseconds
      };

      // Add scroll event listener to recheck position after scrolling stops
      window.addEventListener("scroll", handleScroll);

      // Initial scroll
      scrollTo();
    }
  }
}
