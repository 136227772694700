import { Controller } from "@hotwired/stimulus";

// Inspired from:
// https://github.com/hotwired/turbo-site/pull/40#issuecomment-815563862
// https://stackoverflow.com/a/71882566/2851845

export default class extends Controller {
  initialize() {
    this.addTurboStreamHeaders = this.addTurboStreamHeaders.bind(this);
  }

  connect() {
    this.element.addEventListener("turbo:before-fetch-request", this.addTurboStreamHeaders);

    if (/^(form)$/i.test(this.element.nodeName)) {
      this.keepFormActionGetParams();
    }
  }

  disconnect() {
    document.removeEventListener("turbo:before-fetch-request", this.addTurboStreamHeaders);
  }

  addTurboStreamHeaders(event) {
    const { headers } = event.detail.fetchOptions || {};

    if (headers) {
      headers.Accept = ["text/vnd.turbo-stream.html", headers.Accept].join(", ");
    }
  }

  keepFormActionGetParams() {
    const url = this.element.getAttribute("action");
    if (url != null && url.includes("?")) {
      const index = url.indexOf("?");
      const action = url.slice(0, index);
      const params = url.slice(index);
      const formActionParams = new URLSearchParams(params);
      const formData = new URLSearchParams(new FormData(this.element));
      formActionParams.forEach((value, key) => {
        if (formData.get(key) == null) {
          const hiddenInput = document.createElement("input");
          hiddenInput.type = "hidden";
          hiddenInput.name = key;
          hiddenInput.value = value;
          hiddenInput.autocomplete = "off";
          this.element.appendChild(hiddenInput);
        }
      });
      this.element.setAttribute("action", action);
    }
  }
}
