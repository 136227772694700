import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = {
    autoplay: {
      type: Boolean,
      default: true,
    },
    prevArrow: {
      type: String,
      default: ".swiper-button-prev",
    },
    nextArrow: {
      type: String,
      default: ".swiper-button-next",
    },
    slidesPerViewMobile: {
      type: Number,
      default: 1.8,
    },
    slidesPerView: {
      type: Number,
      default: 3,
    },
    slidesPerGroup: {
      type: Number,
      default: 3,
    },
    spaceBetweenSlides: {
      type: Number,
      default: 30,
    },
    autoplayIntervalDelay: {
      type: Number,
      default: 2500,
    },
  };

  connect() {
    this.loadResources()
      .then(() => this.initializeSwiper())
      .catch((error) => {
        console.error("Error loading Swiper resources.", error);
      });
  }

  loadResources() {
    return Promise.all([
      this.loadStylesheet("https://cdn.jsdelivr.net/npm/swiper@11.1.14/swiper-bundle.min.css"),
      this.loadScript("https://cdn.jsdelivr.net/npm/swiper@11.1.14/swiper-bundle.min.js"),
    ]);
  }

  loadScript(src) {
    return new Promise((resolve, reject) => {
      const script = document.createElement("script");
      script.src = src;
      script.dataset.turboTrack = "reload";
      script.dataset.turboEval = "false";
      script.async = "async";
      script.defer = "defer";
      script.crossorigin = "anonymous";
      script.referrerpolicy = "no-referrer";
      script.onload = resolve;
      script.onerror = reject;
      document.body.appendChild(script);
    });
  }

  loadStylesheet(href) {
    return new Promise((resolve, reject) => {
      const link = document.createElement("link");
      link.rel = "preload";
      link.as = "style";
      link.crossorigin = "anonymous";
      link.referrerpolicy = "no-referrer";
      link.href = href;
      link.onload = () => {
        this.onload = null;
        link.rel = "stylesheet";
        resolve();
      };
      link.onerror = reject;
      document.body.appendChild(link);
      if (link.rel !== "preload") link.rel = "stylesheet";
    });
  }

  initializeSwiper() {
    const swiperParams = {
      autoHeight: false,
      slidesPerView: this.slidesPerViewMobileValue,
      slidesPerGroup: 1,
      loop: true,
      direction: "horizontal",
      centeredSlides: true,
      mousewheel: false,
      observeParents: true,
      observeSlideChildren: true,
      observer: true,
      cssMode: false,
      keyboard: false,
      spaceBetween: 20,
      watchSlidesVisibility: true,
      preloadImages: false,
      effect: "slide",
      resizeEvent: "auto",
      breakpoints: {
        480: {
          spaceBetween: this.spaceBetweenSlidesValue,
          slidesPerView: this.slidesPerViewValue,
          slidesPerGroup: this.slidesPerGroupValue,
          centeredSlides: false,
        },
      },
    };

    if (this.autoplayValue) {
      swiperParams.autoplay = {
        delay: this.autoplayIntervalDelayValue,
        disableOnInteraction: true,
        pauseOnMouseEnter: true,
      };
    }

    const prevEl = this.getNavigationElement(this.prevArrowValue);
    const nextEl = this.getNavigationElement(this.nextArrowValue);

    if (prevEl || nextEl) {
      swiperParams.navigation = {
        prevEl,
        nextEl,
      };
    }

    if (!this.element.classList.contains("swiper-initialized")) {
      this.swiper = new Swiper(this.element, swiperParams);
    }
  }

  getNavigationElement(selector) {
    if (!selector) return null;
    return (
      this.element.closest(selector) ||
      this.element.parentElement.querySelector(selector) ||
      this.element.parentElement.parentElement.querySelector(selector) ||
      selector
    );
  }
}
