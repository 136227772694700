import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["detail"];

  connect() {
    this.detailTargets.forEach((detail) => {
      detail.addEventListener("toggle", this.handleToggle.bind(this));
    });
  }

  handleToggle(event) {
    if (!event.target.open) return;

    this.detailTargets.forEach((detail) => {
      if (detail !== event.target) {
        detail.removeAttribute("open");
      }
    });
  }

  disconnect() {
    this.detailTargets.forEach((detail) => {
      detail.removeEventListener("toggle", this.handleToggle);
    });
  }
}
