import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["checkbox", "button"];

  connect() {
    this.toggleButtonClass();
  }

  onButtonClick() {
    this.toggleCheckbox();
    this.toggleButtonClass();
  }

  toggleCheckbox() {
    this.checkboxTarget.checked = !this.checkboxTarget.checked;
  }

  toggleButtonClass() {
    const isActive = this.checkboxTarget.checked;
    this.buttonTarget.classList.toggle("active", isActive);
  }
}
