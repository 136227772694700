import { Controller } from "@hotwired/stimulus";
import { useMutation } from "stimulus-use";

// Observe when src attribute of the frame change, we changed url

export default class extends Controller {
  static values = {
    attribute: {
      type: String,
      default: "src",
    },
  };

  connect() {
    useMutation(this, { attributes: true });
  }

  mutate(entries) {
    entries.forEach((mutation) => {
      if (mutation.type === "attributes" && mutation.attributeName === this.attributeValue) {
        const src = this.element.getAttribute(this.attributeValue);
        if (src != null) {
          window.history.pushState(null, document.title, new URL(src));
        }
      }
    });
  }
}
