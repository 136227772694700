import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = {
    href: {
      type: String,
      default: "",
    },
    target: {
      type: String,
      default: "_top",
    },
  };

  initialize() {
    this.element.setAttribute("data-action", "click->element-link#click");
    this.element.setAttribute("role", "link");
    this.element.classList.add("cursor-pointer");
  }

  click(e) {
    var closestLink = e.target.closest("a");

    if (closestLink == null || closestLink.href == this.hrefValue) {
      e.preventDefault();

      var click = e.which;

      if (this.targetValue == "_blank" || click == 2 || (click == 1 && (e.ctrlKey || e.metaKey))) {
        window.open(this.hrefValue, "_blank");
        window.focus();
      } else if (click == 1) {
        window.location.href = this.hrefValue;
      }
      return true;
    }
  }
}
