import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static get targets() {
    return ["kilometers", "value", "co2"];
  }

  connect() {
    if (this._co2PerKm == undefined) {
      this._co2PerKm = 0.1196;
    }
  }

  onInput() {
    this.valueTarget.innerHTML = this.kilometersTarget.value;
    const co2Savings = parseInt(this.kilometersTarget.value * this._co2PerKm, 10);
    this.co2Target.innerHTML = co2Savings;
  }
}
