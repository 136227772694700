import { Controller } from "@hotwired/stimulus";
import { useResize } from "stimulus-use";

export default class extends Controller {
  static targets = ["form", "desktopLocation", "mobileLocation"];
  static values = {
    breakpoint: {
      type: Number,
      default: 576,
    },
  };

  connect() {
    useResize(this);
    this.isMobile = this.isCurrentlyMobile();
    this.moveForm();
  }

  resize() {
    const isCurrentlyMobile = this.isCurrentlyMobile();

    if (this.isMobile !== isCurrentlyMobile) {
      this.isMobile = isCurrentlyMobile;
      this.moveForm();
    }
  }

  moveForm() {
    if (!this.hasFormTarget || !this.hasDesktopLocationTarget || !this.hasMobileLocationTarget) {
      console.warn("Missing necessary targets");
      return;
    }

    const targetLocation = this.isMobile ? this.mobileLocationTarget : this.desktopLocationTarget;
    targetLocation.appendChild(this.formTarget);
  }

  isCurrentlyMobile() {
    return window.innerWidth <= this.breakpointValue;
  }
}
