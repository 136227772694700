import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = { datalayerParkingIds: Array };

  connect() {
    try {
      window.dataLayer.push({
        search_results_ids: this.datalayerParkingIdsValue,
      });
    } catch (err) {
      console.error(error);
    }

    const parkingRows = document.querySelectorAll(".parking-row");

    parkingRows.forEach((parkingRow) => {
      parkingRow.addEventListener("mouseover", function () {
        Array.from(this.parentNode.children).forEach((sibling) => {
          if (sibling !== this) {
            sibling.classList.add("parking-row--unfocus");
          }
        });
        this.classList.add("parking-row--focus");
        this.classList.remove("parking-row--unfocus");
        var marker = document.getElementById(`marker-${this.id}`);
        if (marker) marker.classList.add("marker-focus");
      });

      parkingRow.addEventListener("mouseleave", function () {
        Array.from(this.parentNode.children).forEach((sibling) => {
          sibling.classList.remove("parking-row--unfocus");
        });
        this.classList.remove("parking-row--focus");
        this.classList.remove("parking-row--unfocus");
        var marker = document.getElementById(`marker-${this.id}`);
        if (marker) marker.classList.remove("marker-focus");
      });
    });

    var searchOverlaysLoading = document.querySelectorAll(".search-overlay-loading");
    if (searchOverlaysLoading) {
      searchOverlaysLoading.forEach((searchOverlayLoading) => searchOverlayLoading.classList.remove("active"));
    }
    window.dispatchEvent(new CustomEvent("new-search-results", { bubbles: true }));
  }
}
