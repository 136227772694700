import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["input", "icon", "button"];

  toggle(event) {
    event.preventDefault();
    event.stopPropagation();

    if (this.inputTarget.getAttribute("type") === "text") {
      this._showPassword();
    } else if (this.inputTarget.getAttribute("type") === "password") {
      this._hidePassword();
    }
  }

  _showPassword() {
    this.inputTarget.setAttribute("type", "password");
  }

  _hidePassword() {
    this.inputTarget.setAttribute("type", "text");
  }
}
