import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["form", "hidden", "radio"];

  toggle(event) {
    const wasActive = event.currentTarget.classList.contains("active");

    this.radioTargets.forEach((radioTarget) => {
      radioTarget.classList.remove("active");
    });

    if (!wasActive) {
      event.currentTarget.classList.add("active");
      this.hiddenTarget.value = event.params.value;
    } else {
      this.hiddenTarget.value = "";
    }

    if (this.formTarget.dataset && this.formTarget.dataset.turbo) {
      this.formTarget.requestSubmit();
    } else {
      this.formTarget.submit();
    }
  }
}
