import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["navlinkSubscriptions", "navlinkBookings"];

  static values = {
    onlyBooking: {
      type: Boolean,
      default: false,
    },
  };

  connect() {
    if (
      !this.onlyBookingValue &&
      this.hasNavlinkSubscriptionsTarget &&
      this.navlinkSubscriptionsTarget.classList.contains("active")
    ) {
      this.subscriptionLayout();
    } else {
      this.bookingLayout();
    }
  }

  subscriptionLayout() {
    // Bannière USP
    let subscriptionUspBanner = document.getElementById("subscriptionUspBanner");
    if (subscriptionUspBanner) {
      subscriptionUspBanner.classList.remove("d-none");
    }

    let bookingUspBanner = document.getElementById("bookingUspBanner");
    if (bookingUspBanner) {
      bookingUspBanner.classList.add("d-none");
    }

    // YesCare
    let yescare = document.getElementById("yescare");
    if (yescare) {
      yescare.classList.remove("d-none");
    }

    // Accès au parking - Accès
    let subscriptionAccess = document.getElementById("subscriptionAccess");
    if (subscriptionAccess) {
      subscriptionAccess.classList.remove("d-none");
    }

    let bookingAccess = document.getElementById("bookingAccess");
    if (bookingAccess) {
      bookingAccess.classList.add("d-none");
    }

    // Accès au parking - Yespass
    let yespass = document.getElementById("yespass");
    if (yespass) {
      yespass.classList.remove("d-none");
    }

    // Les plus du parking - Fonctionnement
    let subscriptionCaracteristics = document.querySelectorAll(".subscriptionCaracteristics");
    if (subscriptionCaracteristics) {
      subscriptionCaracteristics.forEach((caracteristic) => caracteristic.classList.remove("d-none"));
    }

    let bookingCaracteristics = document.querySelectorAll(".bookingCaracteristics");
    if (bookingCaracteristics) {
      bookingCaracteristics.forEach((caracteristic) => caracteristic.classList.add("d-none"));
    }

    // Yespark Pro + Places disponibles
    let yesparkpro = document.getElementById("yesparkpro");
    if (yesparkpro) {
      yesparkpro.classList.remove("d-none");
    }
  }

  bookingLayout() {
    // Bannière USP
    let subscriptionUspBanner = document.getElementById("subscriptionUspBanner");
    if (subscriptionUspBanner) {
      subscriptionUspBanner.classList.add("d-none");
    }

    let bookingUspBanner = document.getElementById("bookingUspBanner");
    if (bookingUspBanner) {
      bookingUspBanner.classList.remove("d-none");
    }

    // YesCare
    let yescare = document.getElementById("yescare");
    if (yescare) {
      yescare.classList.add("d-none");
    }

    // Accès au parking - Accès
    let subscriptionAccess = document.getElementById("subscriptionAccess");
    if (subscriptionAccess) {
      subscriptionAccess.classList.add("d-none");
    }

    let bookingAccess = document.getElementById("bookingAccess");
    if (bookingAccess) {
      bookingAccess.classList.remove("d-none");
    }

    // Accès au parking - Yespass
    let yespass = document.getElementById("yespass");
    if (yespass) {
      yespass.classList.add("d-none");
    }

    // Les plus du parking - Fonctionnement
    let subscriptionCaracteristics = document.querySelectorAll(".subscriptionCaracteristics");
    if (subscriptionCaracteristics) {
      subscriptionCaracteristics.forEach((caracteristic) => caracteristic.classList.add("d-none"));
    }

    let bookingCaracteristics = document.querySelectorAll(".bookingCaracteristics");
    if (bookingCaracteristics) {
      bookingCaracteristics.forEach((caracteristic) => caracteristic.classList.remove("d-none"));
    }

    // Yespark Pro + Places disponibles
    let yesparkpro = document.getElementById("yesparkpro");
    if (yesparkpro) {
      yesparkpro.classList.add("d-none");
    }
  }
}
