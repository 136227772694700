import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  onChangedAddress(event) {
    this.updateInputValue("input#multi_spots_address", event.currentTarget.value);
  }

  onChangedEmail(event) {
    this.updateInputValue("input#multi_spots_email", event.currentTarget.value);
  }

  onChangedZipcode(event) {
    this.updateInputValue("input#multi_spots_zipcode", event.currentTarget.value);
  }

  onChangedCity(event) {
    this.updateInputValue("input#multi_spots_city", event.currentTarget.value);
  }

  updateInputValue(selector, value) {
    const input = document.querySelector(selector);
    if (input) input.value = value;
  }
}
