import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["createAlert", "createTemporaryAccess", "createProRequest", "desiredSpotsCount", "submitButton"];

  connect() {
    this.disabledSubmitButton();
  }

  displaySubmitButton() {
    if (
      this.createAlertTarget.checked ||
      (this.hasCreateTemporaryAccessTarget && this.createTemporaryAccessTarget.checked) ||
      this.createProRequestTarget.checked
    ) {
      this.submitButtonTarget.removeAttribute("disabled");
    } else {
      this.disabledSubmitButton();
    }
  }

  displayDesiredSpotsCount() {
    if (this.createProRequestTarget.checked) {
      this.desiredSpotsCountTarget.classList.remove("d-none");
      this.desiredSpotsCountTarget.setAttribute("required", "required");
    } else {
      this.desiredSpotsCountTarget.classList.add("d-none");
      this.desiredSpotsCountTarget.removeAttribute("required");
    }
  }

  disabledSubmitButton() {
    this.submitButtonTarget.setAttribute("disabled", "disabled");
  }
}
