import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["link"];

  openLink(e) {
    e.preventDefault();
    if (this.hasLinkTarget && this.linkTarget.dataset && this.linkTarget.dataset.src) {
      window.location = this.linkTarget.dataset.src;
    }
  }

  openLinkInNewTab(e) {
    e.preventDefault();
    if (this.hasLinkTarget && this.linkTarget.dataset && this.linkTarget.dataset.src) {
      window.open(this.linkTarget.dataset.src, "_blank");
    }
  }
}
