import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = {
    delay: {
      type: Number,
      default: 2000,
    },
  };

  connect() {
    if (this.readCookie("popup_mobile_stores") != "1") {
      window.setTimeout(() => {
        this.element.classList.add("show");
        this.element.addEventListener("click", this.closeIfClickOutside.bind(this), { passive: true });
      }, this.delayValue);
    }
  }

  disconnect() {
    this.overlayTarget.removeEventListener("click", this.closeIfClickOutside.bind(this), { passive: true });
  }

  readCookie(name) {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(";").shift();
  }

  setCookie(name, value, days) {
    let expires = "";
    if (days) {
      const date = new Date();
      date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
      expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + (value || "") + expires + "; path=/";
  }

  close() {
    this.element.classList.remove("show");
    this.setCookie("popup_mobile_stores", "1", 2);
  }

  closeIfClickOutside(event) {
    if (!event.target.closest(".popup-mobile-stores--card")) {
      this.close();
    }
  }
}
