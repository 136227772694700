import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["userPassword", "passwordFeedback"];

  passwordChanged() {
    if (this.userPasswordTarget.value.length < 8) {
      this.passwordFeedbackTarget.style.color = "#ec4838";
    } else {
      this.passwordFeedbackTarget.style.color = "#4ace47";
    }
  }
}
