import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["form"];

  static values = {
    computeOnConnect: {
      type: Boolean,
      default: false,
    },
    minimalDurationInHours: Number,
    toastErrorMinimalDurationInHoursTitle: String,
    toastErrorMinimalDurationInHoursBody: String,
    toastErrorDateOrderTitle: String,
    toastErrorDateOrderBody: String,
    autosubmit: {
      type: Boolean,
      default: true,
    },
  };

  connect() {
    if (this.computeOnConnectValue) this.computePrice();
  }

  computePrice(e) {
    var startDate = document.querySelector("#start_date").value;
    var startTime = document.querySelector("#start_time").value;
    var endDate = document.querySelector("#end_date").value;
    var endTime = document.querySelector("#end_time").value;

    if (e && e.currentTarget && e.currentTarget && e.currentTarget.id == "start_date" && endDate == "") {
      document.querySelector("#end_date").min = startDate;
      document.querySelector("#end_date").setAttribute("data-flatpicker-min-date-value", startDate);
      document.querySelector("#end_date")._flatpickr.set("minDate", startDate);
    }

    if (startDate != "" && startTime != "" && endDate != "" && endTime != "") {
      var formattedStartDate = new Date(`${startDate} ${startTime}`);
      var formattedEndDate = new Date(`${endDate} ${endTime}`);
      var differenceInMs = formattedEndDate - formattedStartDate;
      var differenceInHours = differenceInMs / (1000 * 60 * 60);

      if (differenceInHours < this.minimalDurationInHoursValue) {
        var minimalEndDate = this.setMinimalEndDate(startDate, startTime);
        this.setNewEndDate(minimalEndDate);
        this.setNewEndTime(minimalEndDate);
        window.dispatchEvent(
          new CustomEvent("new-toast", {
            detail: {
              type: "info",
              title: this.toastErrorMinimalDurationInHoursTitleValue,
              message: this.toastErrorMinimalDurationInHoursBodyValue,
              autocloseDelay: 5000,
            },
            bubbles: true,
          }),
        );
      }

      if (e && e.currentTarget) {
        if (e.currentTarget.id == "start_date" || e.currentTarget.id == "start_time") {
          this.dispatchShortTermBookingInputChanged({ kind: "start" });
        } else {
          this.dispatchShortTermBookingInputChanged({ kind: "end" });
        }
      }

      if (this.autosubmitValue || this.computeOnConnectValue) {
        if (this.hasFormTarget) {
          if (this.formTarget.dataset.turbo) {
            this.formTarget.requestSubmit();
          } else {
            this.formTarget.submit();
          }
        }
      }
    } else {
      if (e && e.currentTarget) {
        if (e.currentTarget.id == "start_date" || e.currentTarget.id == "start_time") {
          this.dispatchShortTermBookingInputChanged({ kind: "start" });
        } else {
          this.dispatchShortTermBookingInputChanged({ kind: "end" });
        }
      }
    }
  }

  dispatchShortTermBookingInputChanged({ kind }) {
    window.dispatchEvent(
      new CustomEvent("short-term-booking-input-changed", { detail: { kind: kind }, bubbles: true }),
    );
  }

  setMinimalEndDate(startDate, startTime) {
    var date = new Date(`${startDate} ${startTime}`);
    var minimalEndDate = date.setTime(date.getTime() + this.minimalDurationInHoursValue * 60 * 60 * 1000);
    return new Date(minimalEndDate);
  }

  setNewEndDate(minimalEndDate) {
    var year = minimalEndDate.getFullYear();
    var month = String(minimalEndDate.getMonth() + 1).padStart(2, "0");
    var day = String(minimalEndDate.getDate()).padStart(2, "0");

    var newEndDate = `${year}-${month}-${day}`;

    if (document.getElementById("end_date").value != newEndDate) {
      document.getElementById("end_date").value = newEndDate;
      document.getElementById("end_date")._flatpickr.setDate(newEndDate, true, "Y-m-d");
    }
  }

  setNewEndTime(minimalEndDate) {
    var hour = String(minimalEndDate.getHours()).padStart(2, "0");

    var newEndTime = `${hour}:00`;

    if (document.getElementById("end_time").value != newEndTime) {
      document.getElementById("end_time").value = newEndTime;
    }
  }
}
