import { Controller } from "@hotwired/stimulus";
import { useResize } from "stimulus-use";

export default class extends Controller {
  static values = {
    mapboxDistJsUrl: String,
    mapboxDistCssUrl: String,
    mapboxToken: String,
    mapboxStyle: String,
    mapboxRegionsData: Object,
    mapboxDepartmentsData: Object,
    mapboxZoom: Number,
    mapboxInitialLat: Number,
    mapboxInitialLng: Number,
    mapboxFocusNortheasternLat: Number,
    mapboxFocusNortheasternLng: Number,
    mapboxFocusSouthwesternLat: Number,
    mapboxFocusSouthwesternLng: Number,
  };

  static targets = ["canvas"];

  connect() {
    Promise.all([this.loadMapboxJavascript(), this.loadMapboxStylesheet()])
      .then(() => {
        this.initializeMapbox();
      })
      .catch((error) => {
        alert("Une erreur est survenue lors du chargement de la carte.");
        console.error(error);
      });
  }

  disconnect() {
    if (this.__map != undefined) {
      this.__map.remove();
      this.__map = undefined;
    }
  }

  loadMapboxJavascript() {
    return new Promise((resolve, reject) => {
      const script = document.createElement("script");
      script.src = this.mapboxDistJsUrlValue;
      script.onload = resolve;
      script.onerror = reject;
      document.head.appendChild(script);
    });
  }

  loadMapboxStylesheet() {
    return new Promise((resolve, reject) => {
      if (document.createStyleSheet) {
        document.createStyleSheet(path);
      } else {
        const link = document.createElement("link");
        link.rel = "stylesheet";
        link.type = "text/css";
        link.media = "all";
        link.href = this.mapboxDistCssUrlValue;
        link.onload = resolve;
        link.onerror = reject;
        document.head.appendChild(link);
      }
    });
  }

  async initializeMapbox() {
    if (typeof mapboxgl === "undefined") {
      console.error("Mapbox GL JS is not loaded");
      return;
    }

    mapboxgl.accessToken = this.mapboxTokenValue;

    this.__map = new mapboxgl.Map({
      container: this.canvasTarget,
      style: this.mapboxStyleValue,
      center: [this.mapboxInitialLngValue, this.mapboxInitialLatValue],
      zoom: this.mapboxZoomValue,
      minZoom: this.mapboxZoomValue,
      maxZoom: 15.4,
      respectPrefersReducedMotion: true,
    });

    this.__mapLoaded = false;
    this.__mapAddedSourcesIds = [];
    this.__mapAddedLayersIds = [];

    this.__mapDepartmentLayerPopup = new mapboxgl.Popup({
      closeButton: false,
      closeOnClick: true,
    });
    this.__mapDepartmentLayerPopupShowingForDepartmentCode = null;

    this.__map.on("load", () => {
      this.addLayers();
      this.__mapLoaded = true;
    });

    this.__map.once("styledata", function () {
      this.getStyle().layers.forEach((l) => {
        if (l.type == "symbol" || l.id.indexOf("road") != -1 || l.id.indexOf("boundary") != -1) {
          this.setLayoutProperty(l.id, "visibility", "none");
        }
      });
    });

    this.__map.addControl(new mapboxgl.NavigationControl({ showCompass: false }));
    this.__map.dragRotate.disable();
    this.__map.touchZoomRotate.disableRotation();

    this.__map.once("idle", async (e) => {
      if (
        this.hasMapboxFocusNortheasternLatValue &&
        this.hasMapboxFocusNortheasternLngValue &&
        this.hasMapboxFocusSouthwesternLatValue &&
        this.hasMapboxFocusSouthwesternLngValue
      ) {
        this.__map.fitBounds(
          [
            [this.mapboxFocusSouthwesternLngValue, this.mapboxFocusSouthwesternLatValue],
            [this.mapboxFocusNortheasternLngValue, this.mapboxFocusNortheasternLatValue],
          ],
          {
            essential: true,
            duration: 2000,
            padding: 160,
          },
        );
      }
      this.__initialSearchMade = true;
    });

    this.__map.once("render", () => {
      this.__map.resize();
    });

    useResize(this);
  }

  resize({ height, width }) {
    if (this.__map) this.__map.resize();
  }

  findLatLng(coordinates) {
    if (Array.isArray(coordinates[0]) && typeof coordinates[0][0] === "object") {
      return this.findLatLng(coordinates[0]);
    } else {
      return coordinates;
    }
  }

  addLayers() {
    this.__mapAddedLayersIds.forEach((id) => {
      if (this.__map.getLayer(id)) this.__map.removeLayer(id);
    });

    this.__mapAddedSourcesIds.forEach((id) => {
      if (this.__map.getSource(id)) this.__map.removeSource(id);
    });

    this.__mapAddedSourcesIds = [];
    this.__mapAddedLayersIds = [];

    this.mapboxDepartmentsDataValue.features.forEach((departmentFeature) => {
      const departmentCode = departmentFeature.properties.code;

      const departmentSource = `source-${departmentCode}`;
      this.__map.addSource(departmentSource, {
        type: "geojson",
        data: {
          type: "Feature",
          geometry: departmentFeature.geometry,
          properties: departmentFeature.properties,
        },
      });
      this.__mapAddedSourcesIds.push(departmentSource);

      const departmentFillLayerId = `department-${departmentCode}`;
      this.__map.addLayer({
        id: departmentFillLayerId,
        type: "fill",
        source: departmentSource,
        layout: {},
        paint: {
          "fill-color": ["get", "color"],
          "fill-opacity": 0.9,
          "fill-outline-color": "#999",
          "fill-antialias": true,
        },
      });
      this.__mapAddedLayersIds.push(departmentFillLayerId);

      if (departmentFeature.properties.outline) {
        const departmentOutlineLayerId = `${departmentFillLayerId}-outline`;
        this.__map.addLayer({
          id: departmentOutlineLayerId,
          type: "line",
          source: departmentSource,
          layout: {},
          paint: {
            "line-color": "#fff",
            "line-width": 3,
            "line-opacity": 0.75,
          },
        });
        this.__mapAddedLayersIds.push(departmentOutlineLayerId);
      }

      this.__map.on("mouseenter", departmentFillLayerId, (event) => {
        var departmentCode = event.features[0].properties["code"];
        if (this.__mapDepartmentLayerPopupShowingForDepartmentCode == departmentCode) {
          return;
        } else if (this.__mapDepartmentLayerPopupShowingForDepartmentCode != departmentCode) {
          this.__mapDepartmentLayerPopup.remove();
        }
        this.__mapDepartmentLayerPopupShowingForDepartmentCode = event.features[0].properties["code"];
        this.__map.getCanvas().style.cursor = "pointer";
        this.__mapDepartmentLayerPopup
          .setLngLat([event.lngLat.lng, event.lngLat.lat])
          .setHTML(event.features[0].properties.description)
          .addTo(this.__map);
      });
    });

    this.mapboxRegionsDataValue.features.forEach((regionFeature) => {
      const regionSlug = regionFeature.properties.slug;

      if (regionSlug) {
        const regionSource = `source-${regionSlug}`;
        this.__map.addSource(regionSource, {
          type: "geojson",
          data: {
            type: "Feature",
            geometry: regionFeature.geometry,
            properties: regionFeature.properties,
          },
        });
        this.__mapAddedSourcesIds.push(regionSource);

        if (regionFeature.properties.outline) {
          const regionOutlineLayerId = `region-${regionSlug}-outline`;
          this.__map.addLayer({
            id: regionOutlineLayerId,
            type: "line",
            source: regionSource,
            layout: {},
            paint: {
              "line-color": "#fff",
              "line-width": 3,
              "line-opacity": 0.75,
            },
          });
          this.__mapAddedLayersIds.push(regionOutlineLayerId);
        }
      }
    });
  }
}
