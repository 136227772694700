import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["element"];

  connect() {
    this.selectElementWithHash(
      window.location.hash == null && this.hasElementTarget ? this.elementTargets[0].href : window.location.hash,
    );
  }

  selectElementWithHash(hash) {
    if (this.hasElementTarget) {
      if (hash != null) {
        var firstElement = this.elementTargets[0];
        if (firstElement.hash != null) {
          hash = firstElement.hash;
        }
      }
      if (hash != null) {
        this.elementTargets.forEach((element) => {
          if (element.hash == hash) {
            element.classList.add("active");
          } else {
            element.classList.remove("active");
          }
        });
      }
    }
  }

  onClick(event) {
    this.selectElementWithHash(event.currentTarget.hash);
  }
}
