import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static get targets() {
    return ["card", "input", "earning"];
  }

  static values = {
    percentage: Number,
  };

  connect() {
    this.onInput();
  }

  onInput() {
    if (this.hasInputTarget && this.hasCardTarget && this.hasEarningTarget) {
      if (this.inputTarget.value && this.inputTarget.value.length > 0) {
        this.cardTarget.style.setProperty("display", "block", "important");
        this.earningTarget.innerHTML = parseInt(
          (parseInt(this.inputTarget.value) * (100 - this.percentageValue)) / 100,
          10,
        );
      } else {
        this.cardTarget.style.setProperty("display", "none", "important");
      }
    }
  }
}
