import { install } from "@github/hotkey";

if (!window.app) {
  window.app = {};
}

document.addEventListener("turbo:before-prefetch", (event) => {
  if (
    navigator.connection?.saveData ||
    navigator.connection?.effectiveType === "slow-2g" ||
    navigator.connection?.effectiveType === "2g" ||
    navigator.connection?.effectiveType === "3g"
  )
    event.preventDefault();
});

// Display progressbar for turbo stream get request
document.addEventListener("turbo:before-fetch-request", (event) => {
  const target = event.target;
  if (!(target instanceof HTMLElement)) return;

  if (target.getAttribute("data-turbo-stream") != null) {
    Turbo.navigator.delegate.adapter.showProgressBar();
  }
});

document.addEventListener("turbo:before-fetch-response", (event) => {
  const target = event.target;
  if (!(target instanceof HTMLElement)) return;

  if (target.getAttribute("data-turbo-stream") != null) {
    Turbo.navigator.delegate.adapter.progressBar.hide();
  }
});

const TURBO_ATTRIBUTES = ["turbo", "turboStream", "turboFrame", "turboAction"];

const hasNoTurboAttributes = (element) => TURBO_ATTRIBUTES.every((attribute) => !(attribute in element.dataset));

const setTargetTopForLinksWithoutTurboAttributes = () => {
  document.querySelectorAll("a").forEach((link) => {
    if (
      link.target === "" &&
      hasNoTurboAttributes(link) &&
      (link.getAttribute("href") == null || !link.getAttribute("href").startsWith("/admin"))
    )
      link.target = "_top";
  });
};

const disableTurboForRemoteForms = () => {
  document.querySelectorAll("form").forEach((form) => {
    if (form.dataset.remote === "true" && hasNoTurboAttributes(form)) form.dataset.turbo = "false";
  });
};

const initializeBootstrapTooltips = () => {
  document
    .querySelectorAll('[data-bs-toggle="tooltip"]:not([data-tooltip-initialized])')
    .forEach((tooltipTriggerEl) => {
      new bootstrap.Tooltip(tooltipTriggerEl);
      tooltipTriggerEl.setAttribute("data-tooltip-initialized", "true");
    });
};

const initializeBootstrapSelectpickers = () => {
  if ($.fn.selectpicker) {
    document.querySelectorAll(":not(.bootstrap-select) > select.selectpicker").forEach((select) => {
      $(select).selectpicker({
        countSelectedText: "{0} éléments",
        deselectAllText: "Tout désélectionner",
        selectAllText: "Tout sélectionner",
        selectedTextFormat: "count > 2",
        style: "",
        styleBase: "form-control bg-white",
      });
    });
  }
};

const processedEvents = new Map();
const processedEventDuplicateThresholdInMilliseconds = 500; // half a second
const processedEventCleanupAfterInMilliseconds = 10000; // 10 seconds

const cleanupProcessedEvents = () => {
  const now = Date.now();
  for (const [eventIdentifier, timestamp] of processedEvents.entries())
    if (now - timestamp > processedEventCleanupAfterInMilliseconds) processedEvents.delete(eventIdentifier);
};

const listenClickOnDelegateElements = (event_name) => {
  document.addEventListener(event_name, async (e) => {
    // Handle disabled links
    if (event_name == "click") {
      const disabledLinkTarget = e.target.closest("a.disabled, a[disabled]");
      if (disabledLinkTarget) {
        e.preventDefault();
        e.stopPropagation();
        return;
      }
    }

    // Send to GA and ahoy the element tracked with data-event-action
    const trackTarget = e.target.closest("[data-event-action]");
    if (trackTarget) {
      let { eventCategory, eventAction, eventLabel } = trackTarget.dataset;
      eventCategory ||= document.body.className;

      // Generate a unique identifier for the event
      const eventIdentifier = `${eventCategory}:${eventAction}:${eventLabel}`;

      // Get the current timestamp
      const now = Date.now();

      // Minimize map access by storing the retrieved value in a variable
      const lastProcessedTimestamp = processedEvents.get(eventIdentifier);

      // If this event has already been processed recently, exit early
      if (lastProcessedTimestamp && now - lastProcessedTimestamp < processedEventDuplicateThresholdInMilliseconds)
        return;

      // Mark this event as processed
      processedEvents.set(eventIdentifier, now);

      // Cleanup old processed event entries whenever a new entry is added
      cleanupProcessedEvents();

      if (dataLayer !== undefined) {
        dataLayer.push({
          event: "eventGA",
          event_category: eventCategory,
          event_action: eventAction,
          event_label: eventLabel,
        });
      }

      if (window.ahoy) {
        window.ahoy.track("$click", {
          category: eventCategory,
          action: eventAction,
          label: eventLabel,
          url: window.location.href,
          user_id: window.user_id,
        });
      }
    }

    if (event_name == "click") {
      // Handle modal close
      const modalCloseTarget = e.target.closest("[data-modal=hide], [data-close-modal]");
      if (modalCloseTarget) {
        e.preventDefault();
        bootstrap.Modal.getInstance(modalCloseTarget.closest(".modal")).hide();
      }

      // Open dynamic modal on click
      const dynamicModalTarget = e.target.closest("[data-dynamic-modal-href]");
      if (dynamicModalTarget) {
        e.preventDefault();
        const modalTarget = document.querySelector(dynamicModalTarget.dataset.target);
        if (modalTarget) {
          const modalBody = modalTarget.querySelector(".modal-body");
          if (modalBody) {
            const href = dynamicModalTarget.dataset.dynamicModalHref;
            if (href) {
              const response = await fetch(href);
              const content = await response.text();
              modalBody.innerHTML = content;
              bootstrap.Modal.getOrCreateInstance(modalTarget).show();
            }
          }
        }
      }
    }
  });
};

const adjustLinksForSmallScreens = () => {
  const windowWidth = window.innerWidth;
  if (windowWidth <= 768) {
    Array.prototype.forEach.call(document.links, (link) => {
      if (
        link.hostname === window.location.hostname &&
        link.target &&
        link.target.length > 0 &&
        (link.target.indexOf("_parking") !== -1 || link.target === "_blank")
      ) {
        link.target = "_self";
        link.removeAttribute("rel");
      }
    });
  }
};

const installHotkeys = () => {
  // Install all the hotkeys on the page
  for (const el of document.querySelectorAll("[data-hotkey]")) {
    install(el);
  }
};

const handleLazyloadBgs = (elements) => {
  if ("IntersectionObserver" in window) {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.style.backgroundImage = `url('${entry.target.dataset.bgimage}')`;
            observer.unobserve(entry.target);
          }
        });
      },
      {
        rootMargin: "100px",
        threshold: 0.01,
      },
    );

    elements.forEach((element) => observer.observe(element));
  } else {
    elements.forEach((element) => {
      element.style.backgroundImage = `url('${element.dataset.bgimage}')`;
    });
  }
};

const handleNewElementsAddedToDOM = () => {
  if (window.newElementsObserver) return;

  window.newElementsObserver = new MutationObserver((mutations) => {
    mutations.forEach((mutation) => {
      if (mutation.type === "childList") {
        const lazyBgs = [];
        mutation.addedNodes.forEach((node) => {
          if (node.nodeType === 1 && node.matches(".lazyload-bg")) {
            lazyBgs.push(node);
          } else if (node.nodeType === 1 && node.querySelectorAll) {
            lazyBgs.push(...node.querySelectorAll(".lazyload-bg"));
          }
        });
        if (lazyBgs.length > 0) {
          handleLazyloadBgs(lazyBgs);
        }
      }
    });
  });

  window.newElementsObserver.observe(document.body, {
    childList: true,
    subtree: true,
  });
};

const callbackTurboLoaded = () => {
  setTargetTopForLinksWithoutTurboAttributes();
  disableTurboForRemoteForms();
  initializeBootstrapTooltips();
  initializeBootstrapSelectpickers();
  adjustLinksForSmallScreens();
  installHotkeys();
  handleLazyloadBgs(document.querySelectorAll(".lazyload-bg"));
};

listenClickOnDelegateElements("click");
listenClickOnDelegateElements("change");

document.addEventListener("turbo:load", () => {
  callbackTurboLoaded();
  handleNewElementsAddedToDOM();
});

document.addEventListener("turbo:frame-load", () => {
  callbackTurboLoaded();
});

window.postToast = ({ type, title, message, autocloseDelay = 10000 }) => {
  if (type == "notice") type = "success";
  var newToast = document.createElement("div");
  newToast.className = "ds-toast pe-none";
  newToast.setAttribute("data-status", type);
  newToast.setAttribute("data-controller", "toast");
  newToast.setAttribute("data-toast-client-side-value", true);
  newToast.setAttribute("data-toast-type-value", type);
  if (title) newToast.setAttribute("data-toast-headline-value", title);
  if (message) newToast.setAttribute("data-toast-details-value", message);
  if (autocloseDelay) newToast.setAttribute("data-toast-autoclose-delay-value", autocloseDelay);
  newToast.setAttribute("data-action", "mouseenter->toast#pause mouseleave->toast#resume");
  document.getElementById("toasts").replaceChildren(newToast);
};

window.addEventListener("new-toast", (event) => {
  window.postToast(event.detail);
});

Turbo.StreamActions.flash_message = function () {
  window.postToast({
    type: this.getAttribute("type"),
    title: this.getAttribute("title"),
    message: this.getAttribute("message"),
    autocloseDelay: this.getAttribute("autocloseDelay"),
  });
};

Turbo.StreamActions.push_data_layer = function () {
  const attributes = JSON.parse(this.getAttribute("attributes"));
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push(attributes);

  if (window.ahoy && attributes.event_category && attributes.event_action) {
    const ahoyKeysMap = {
      event_category: "category",
      event_action: "action",
      event_label: "label",
    };

    const ahoyPayload = Object.keys(attributes).reduce(
      (acc, key) => {
        acc[ahoyKeysMap[key] || key] = attributes[key];
        return acc;
      },
      { url: window.location.href, user_id: window.user_id },
    );

    window.ahoy.track("$click", ahoyPayload);
  }
};
