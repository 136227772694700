import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [
    "option",
    "input",
    "submitButton",
    "typeformButton",
    "hdshTypeformButton",
    "devisRechargeButton",
    "checkoutRechargeButton",
    "alertButton",
    "modalButton",
  ];

  static values = {
    wordingAlertCreate: String,
    wordingAlertCreated: String,
    wordingTypeformPreorder: String,
    wordingTypeformContact: String,
  };

  connect() {
    const selectedOption = this.optionTargets.filter((item) => item.classList.contains("active"))[0];
    if (this.hasTypeformButtonTarget) {
      this.displayButtons(selectedOption);
    }
  }

  selectRadioOption(event) {
    if (!this.hasTypeformButtonTarget) return;

    this.optionTargets.forEach((item, _) => {
      item.classList.toggle("active", event.currentTarget == item);
    });

    var optionValue = event.currentTarget.dataset.optionValue;
    if (optionValue) {
      this.inputTarget.setAttribute("value", event.currentTarget.dataset.optionValue);
    }

    this.displayButtons(event.currentTarget);
  }

  changeTargetUrlParams(target, changes) {
    var urlString = target.href || target.dataset.src;
    if (!urlString) return;

    var urlObject = new URL(urlString);
    var urlParams = urlObject.searchParams;
    changes.forEach(function (change, _index) {
      urlParams.set(change[0], change[1]);
    });
    var newUrl = urlObject.toString();
    if (target.href) {
      target.setAttribute("href", newUrl);
    } else {
      target.setAttribute("data-src", newUrl);
    }
  }

  changeTypeformSource(target, source) {
    let url = new URL(target.href);
    url.searchParams.set("source", source);
    target.href = url.toString();
  }

  displayButtons(selectedOption) {
    let isElectric = selectedOption.dataset.isElectric;
    let isElectricShared = selectedOption.dataset.isElectricShared;
    let hasOngoingCancellation = selectedOption.dataset.hasOngoingCancellation;
    let hasThermalSubscription = selectedOption.dataset.hasThermalSubscription;
    let isFull = selectedOption.dataset.isFull;
    let isInstallation = selectedOption.dataset.isInstallation;
    let onAlert = selectedOption.dataset.onAlert;
    let alertUrl = selectedOption.dataset.alertUrl;
    let isFake = selectedOption.dataset.isFakeElectric;
    let allowPreorder = selectedOption.dataset.allowPreorder;
    let isPriority = selectedOption.dataset.isPriority;
    let spotTypeId = selectedOption.dataset.optionValue;
    let bsTarget = selectedOption.dataset.bsTarget;
    let eventAction = selectedOption.dataset.eventAction;
    let isHdsh = selectedOption.dataset.isHdsh;
    let source = "checkout";

    // Smoke Test semaine weekend
    if (bsTarget) {
      this.modalButtonTarget.classList.remove("d-none");
      this.modalButtonTarget.dataset.bsTarget = bsTarget;
      this.modalButtonTarget.dataset.eventAction = `${eventAction}-submit`;
      this.alertButtonTarget.classList.add("d-none");
      this.submitButtonTarget.classList.add("d-none");
      if (this.hasDevisRechargeButtonTarget) this.devisRechargeButtonTarget.classList.add("d-none");
      if (this.hasCheckoutRechargeButtonTarget) this.checkoutRechargeButtonTarget.classList.add("d-none");
      this.typeformButtonTarget.classList.add("d-none");
      this.hdshTypeformButtonTarget.classList.add("d-none");
    }

    // WEB PARKING DISPO BORNE PARTAGÉE
    else if (isElectricShared == "true" && isFake == "false") {
      source = "web_parking_dispo_borne_partagee";
      if (this.hasModalButtonTarget) this.modalButtonTarget.classList.add("d-none");
      this.submitButtonTarget.classList.add("d-none");
      this.alertButtonTarget.classList.add("d-none");
      this.hdshTypeformButtonTarget.classList.add("d-none");
      if (this.hasDevisRechargeButtonTarget) {
        this.devisRechargeButtonTarget.classList.add("d-none");
        this.checkoutRechargeButtonTarget.classList.add("d-none");
        this.changeTargetUrlParams(this.devisRechargeButtonTarget, [
          ["source", source],
          ["spot_type_id", spotTypeId],
        ]);
      }
      this.changeTypeformSource(this.typeformButtonTarget, source);
      this.typeformButtonTarget.classList.remove("d-none");
    }

    // WEB PARKING RESILIATION
    else if (isElectric == "true" && isFull == "true" && isFake == "false" && hasOngoingCancellation == "true") {
      source = "web_parking_résiliation";
      if (this.hasModalButtonTarget) this.modalButtonTarget.classList.add("d-none");
      this.submitButtonTarget.classList.add("d-none");
      this.alertButtonTarget.classList.add("d-none");
      this.hdshTypeformButtonTarget.classList.add("d-none");
      if (this.hasDevisRechargeButtonTarget) {
        this.devisRechargeButtonTarget.classList.add("d-none");
        this.checkoutRechargeButtonTarget.classList.add("d-none");
        this.changeTargetUrlParams(this.devisRechargeButtonTarget, [
          ["source", source],
          ["spot_type_id", spotTypeId],
        ]);
      }
      this.changeTypeformSource(this.typeformButtonTarget, source);
      this.typeformButtonTarget.classList.remove("d-none");
    }

    // WEB PARKING POST LOCATION VT
    else if (isElectric == "true" && isFull == "true" && isFake == "false" && hasThermalSubscription == "true") {
      source = "web_parking_post_location_VT";
      if (this.hasModalButtonTarget) this.modalButtonTarget.classList.add("d-none");
      this.submitButtonTarget.classList.add("d-none");
      this.alertButtonTarget.classList.add("d-none");
      this.hdshTypeformButtonTarget.classList.add("d-none");
      if (this.hasDevisRechargeButtonTarget) {
        this.devisRechargeButtonTarget.classList.add("d-none");
        this.checkoutRechargeButtonTarget.classList.add("d-none");
        this.changeTargetUrlParams(this.devisRechargeButtonTarget, [
          ["source", source],
          ["spot_type_id", spotTypeId],
        ]);
      }
      this.changeTypeformSource(this.typeformButtonTarget, source);
      this.typeformButtonTarget.classList.remove("d-none");
    }

    // WEB PARKING INSTALLABLE
    else if (
      isFull == "true" &&
      isElectric == "true" &&
      (isInstallation == "true" || (isFake == "true" && allowPreorder == "true"))
    ) {
      if (isFake == "true" && allowPreorder == "true") {
        source = "web_parking_installable";
      } else {
        source = "web_parking_installation";
      }
      if (this.hasModalButtonTarget) this.modalButtonTarget.classList.add("d-none");
      this.submitButtonTarget.classList.add("d-none");
      this.alertButtonTarget.classList.add("d-none");
      this.hdshTypeformButtonTarget.classList.add("d-none");
      if (this.hasDevisRechargeButtonTarget) {
        this.devisRechargeButtonTarget.classList.add("d-none");
        this.checkoutRechargeButtonTarget.classList.add("d-none");
        this.changeTargetUrlParams(this.devisRechargeButtonTarget, [
          ["source", source],
          ["spot_type_id", spotTypeId],
        ]);
      }
      this.typeformButtonTarget.innerText = this.wordingTypeformPreorderValue;
      this.changeTypeformSource(this.typeformButtonTarget, source);
      this.typeformButtonTarget.classList.remove("d-none");
    }

    // WEB PARKING HDSH
    else if (isElectric == "true" && isFull == "true" && isFake == "false" && isHdsh == "true") {
      source = "web_parking_hdsh";
      if (this.hasModalButtonTarget) this.modalButtonTarget.classList.add("d-none");
      this.submitButtonTarget.classList.add("d-none");
      this.alertButtonTarget.classList.add("d-none");
      this.typeformButtonTarget.classList.add("d-none");
      if (this.hasDevisRechargeButtonTarget) {
        this.devisRechargeButtonTarget.classList.add("d-none");
        this.checkoutRechargeButtonTarget.classList.add("d-none");
      }
      this.hdshTypeformButtonTarget.classList.remove("d-none");
    }

    // WEB PARKING COMPLET
    else if (isElectric == "true" && isFull == "true" && isFake == "false") {
      source = "web_parking_complet";
      if (this.hasModalButtonTarget) this.modalButtonTarget.classList.add("d-none");
      this.submitButtonTarget.classList.add("d-none");
      this.alertButtonTarget.classList.add("d-none");
      this.hdshTypeformButtonTarget.classList.add("d-none");
      if (this.hasDevisRechargeButtonTarget) {
        this.devisRechargeButtonTarget.classList.add("d-none");
        this.checkoutRechargeButtonTarget.classList.add("d-none");
        this.changeTargetUrlParams(this.devisRechargeButtonTarget, [
          ["source", source],
          ["spot_type_id", spotTypeId],
        ]);
      }
      this.changeTypeformSource(this.typeformButtonTarget, source);
      this.typeformButtonTarget.classList.remove("d-none");
    }

    // WEB PARKING AUTRE
    else if (isElectric == "true" && isFake == "true" && isPriority == "true") {
      source = "web_parking_autre";
      if (this.hasModalButtonTarget) this.modalButtonTarget.classList.add("d-none");
      this.submitButtonTarget.classList.add("d-none");
      this.alertButtonTarget.classList.add("d-none");
      this.hdshTypeformButtonTarget.classList.add("d-none");
      if (this.hasDevisRechargeButtonTarget) {
        this.devisRechargeButtonTarget.classList.add("d-none");
        this.checkoutRechargeButtonTarget.classList.add("d-none");
        this.changeTargetUrlParams(this.devisRechargeButtonTarget, [
          ["source", source],
          ["spot_type_id", spotTypeId],
        ]);
      }
      this.changeTypeformSource(this.typeformButtonTarget, source);
      this.typeformButtonTarget.innerText = this.wordingTypeformContactValue;
      this.typeformButtonTarget.classList.remove("d-none");
    }

    // ALERT
    else if (isFull == "true" || isFake == "true") {
      if (this.hasModalButtonTarget) this.modalButtonTarget.classList.add("d-none");
      this.typeformButtonTarget.classList.add("d-none");
      this.hdshTypeformButtonTarget.classList.add("d-none");
      if (this.hasDevisRechargeButtonTarget) this.devisRechargeButtonTarget.classList.add("d-none");
      if (this.hasCheckoutRechargeButtonTarget) this.checkoutRechargeButtonTarget.classList.add("d-none");
      this.submitButtonTarget.classList.add("d-none");
      if (onAlert == "true") {
        this.alertButtonTarget.innerText = this.wordingAlertCreatedValue;
      } else {
        this.alertButtonTarget.innerText = this.wordingAlertCreateValue;
      }
      this.alertButtonTarget.setAttribute("href", alertUrl);
      this.alertButtonTarget.classList.remove("d-none");
    }

    // WEB PARKING DISPO
    else if (isElectric == "true" && isFull == "false" && isFake == "false") {
      if (this.hasModalButtonTarget) this.modalButtonTarget.classList.add("d-none");
      this.submitButtonTarget.classList.add("d-none");
      this.alertButtonTarget.classList.add("d-none");
      this.hdshTypeformButtonTarget.classList.add("d-none");
      if (this.hasDevisRechargeButtonTarget) {
        this.changeTargetUrlParams(this.devisRechargeButtonTarget, [
          ["source", "web_parking_dispo_devis"],
          ["spot_type_id", spotTypeId],
        ]);
        this.devisRechargeButtonTarget.classList.remove("d-none");
        if (this.hasCheckoutRechargeButtonTarget) {
          this.changeTargetUrlParams(this.checkoutRechargeButtonTarget, [
            ["source", "web_parking_dispo_checkout"],
            ["spot_type_id", spotTypeId],
          ]);
          this.checkoutRechargeButtonTarget.classList.remove("d-none");
        }
        this.typeformButtonTarget.classList.add("d-none");
      } else {
        this.changeTargetUrlParams(this.typeformButtonTarget, [
          ["hidden[source]", "web_parking_dispo"],
          ["hidden[checkout_url_spot_type_id]", spotTypeId],
        ]);
        this.typeformButtonTarget.classList.remove("d-none");
      }
    }

    // AUTRE
    else {
      if (this.hasModalButtonTarget) this.modalButtonTarget.classList.add("d-none");
      this.alertButtonTarget.classList.add("d-none");
      this.submitButtonTarget.classList.remove("d-none");
      this.hdshTypeformButtonTarget.classList.add("d-none");
      if (this.hasDevisRechargeButtonTarget) this.devisRechargeButtonTarget.classList.add("d-none");
      if (this.hasCheckoutRechargeButtonTarget) this.checkoutRechargeButtonTarget.classList.add("d-none");
      this.typeformButtonTarget.classList.add("d-none");
    }
  }
}
