import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [];

  connect() {
    this.element.style.opacity = 0.4;
    this.disableElement(this.element);
    Array.from(this.element.elements).forEach((formElement) => this.disableElement(formElement));
  }

  disableElement(element) {
    element.classList.add("pe-none");
    element.setAttribute("tabindex", "-1");
    element.setAttribute("aria-disabled", "true");
    element.disabled = true;
    element.readOnly = true;
  }
}
