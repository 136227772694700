import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["slide"];

  static values = {
    neighbors: {
      type: Boolean,
      default: false,
    }
  };

  connect() {
    if (this.neighborsValue)
      this.cloningSlidesToSeeBitOfPreviousAndNextSlides();
  }

  cloningSlidesToSeeBitOfPreviousAndNextSlides() {
    $(this.slideTargets).each(function(){
      var next = $(this).next();
      if (! next.length) {
        next = $(this).siblings(':first');
      }
      next.children(':first-child').clone().appendTo($(this));
    }).each(function(){
      var prev = $(this).prev();
      if (! prev.length) {
        prev = $(this).siblings(':last');
      }
      prev.children(':nth-last-child(2)').clone().prependTo($(this));
    });
  }
}
