import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = {
    videoId: String,
    theme: {
      type: String,
      default: "light",
    },
  };

  connect() {
    document.addEventListener("click", (e) => {
      if (e.target.matches(".grtyoutube-popup-close, .grtyoutube-popup")) {
        e.preventDefault();
        document.querySelectorAll(".grtyoutube-popup").forEach((el) => el.remove());
      }
    });

    document.addEventListener("keyup", (e) => {
      if (e.key === "Escape") {
        document.querySelectorAll(".grtyoutube-popup").forEach((el) => el.remove());
      }
    });
  }

  open(e) {
    e.preventDefault();

    const popup = document.createElement("div");
    popup.className = `grtyoutube-popup grtyoutube-${this.themeValue}-theme`;

    const popupContent = document.createElement("div");
    popupContent.className = "grtyoutube-popup-content";

    const closeButton = document.createElement("span");
    closeButton.className = "grtyoutube-popup-close";

    const iframe = document.createElement("iframe");
    iframe.className = "grtyoutube-iframe";
    iframe.src = `https://www.youtube.com/embed/${this.videoIdValue}?rel=0&wmode=transparent&autoplay=true&iv_load_policy=3`;
    iframe.allowFullscreen = true;
    iframe.frameBorder = "0";
    iframe.allow = "autoplay; fullscreen";

    popupContent.appendChild(closeButton);
    popupContent.appendChild(iframe);
    popup.appendChild(popupContent);
    document.body.appendChild(popup);
  }
}
