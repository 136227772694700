class GoogleMapsPlacesService {
  constructor({ apiKey, country, language, region }) {
    this.apiKey = apiKey;
    this.country = country;
    this.language = language;
    this.region = region;

    this.sessionToken = this.generateSessionToken();
  }

  // https://stackoverflow.com/a/2117523
  generateSessionToken() {
    if (typeof crypto.randomUUID === "function") return crypto.randomUUID();
    return "10000000-1000-4000-8000-100000000000".replace(/[018]/g, (c) =>
      (+c ^ (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (+c / 4)))).toString(16),
    );
  }

  getPlacePredictions(request, callback) {
    const modifiedRequest = {
      includedRegionCodes: [this.region],
      languageCode: this.language,
      regionCode: this.region,
      sessionToken: this.sessionToken,
      ...request,
    };

    fetch("https://places.googleapis.com/v1/places:autocomplete", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-Goog-Api-Key": this.apiKey,
      },
      body: JSON.stringify(modifiedRequest),
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        callback(data);
      })
      .catch((error) => {
        callback({ error: { status: "UNKNOWN_ERROR", message: error } });
      });
  }

  getPlaceDetails(placeId, fields, callback) {
    const request = {
      includedRegionCodes: [this.region],
      languageCode: this.language,
      regionCode: this.region,
      sessionToken: this.sessionToken,
    };

    fetch(`https://places.googleapis.com/v1/places/${placeId}`, {
      method: "GET",
      params: request,
      headers: {
        "Content-Type": "application/json",
        "X-Goog-Api-Key": this.apiKey,
        "X-Goog-FieldMask": fields,
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        callback(data);
      })
      .catch((error) => {
        callback({ error: { status: "UNKNOWN_ERROR", message: error } });
      });
  }
}

export default GoogleMapsPlacesService;
