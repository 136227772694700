import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = {
    active: {
      type: String,
      default: "false",
    },
  };

  connect() {
    if (this.activeValue == "true") {
      if (this.element.tagName == "BUTTON" && this.element.form) {
        window.setTimeout(() => {
          this.element.form.requestSubmit();
        }, 0);
      } else {
        this.element.click();
      }
    }
  }
}
