import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["menuItem", "contentItem"];

  menuItemClicked(event) {
    const index = event.params.index;

    this.menuItemTargets.forEach((menuItem, idx) => {
      menuItem.style.opacity = idx === index ? "1.0" : "0.5";
    });

    this.contentItemTargets.forEach((contentItem, idx) => {
      contentItem.style.display = idx === index ? "flex" : "none";
    });
  }
}
