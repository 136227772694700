import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["preview", "input"];

  connect() {
    $(this.inputTarget).bootstrapSlider().on("change", this.sliderChanged.bind(this));
  }

  sliderChanged(slideEvt) {
    this.previewTarget.innerHTML = slideEvt.value.newValue;
  }
}
