import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = { count: Number };
  static targets = ["item"];

  connect() {
    this.itemTargets.slice(this.countValue).forEach((item) => {
      item.classList.add("d-none");
    });
  }

  seeMore(event) {
    event.preventDefault();

    event.currentTarget.remove();

    this.itemTargets.slice(this.countValue).forEach((item) => {
      item.classList.remove("d-none");
    });
  }
}
