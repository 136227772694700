import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["link"];

  onChange(event) {
    let path = event.target.value;

    if (path == 1) {
      let linkTo = "https://yespark.typeform.com/to/SOTetUf2#source=landing&partenaire=undefined";
      this.linkTarget.setAttribute("href", linkTo);
    } else if (path == "") {
      this.linkTarget.setAttribute("href", "#section-owner");
    } else {
      let linkTo = "https://www.yespark.fr/resident";
      this.linkTarget.setAttribute("href", linkTo + path);
    }
  }
}
