import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    this.modalSearch = document.querySelector("#modalSearch");
    this.modalSearch.addEventListener("hide.bs.modal", this.fillFakeSearchBar.bind(this));

    this.inputSelectors = "input, select";
    this.tabSelectors = '[data-bs-toggle="tab"]';

    this.modalSearch.querySelectorAll(this.inputSelectors).forEach((input) => {
      input.addEventListener("change", this.fillFakeSearchBar.bind(this));
    });

    this.modalSearch.querySelectorAll(this.tabSelectors).forEach((tab) => {
      tab.addEventListener("click", this.fillFakeSearchBar.bind(this));
    });
  }

  disconnect() {
    this.modalSearch.removeEventListener("hide.bs.modal", this.fillFakeSearchBar.bind(this));

    this.modalSearch.querySelectorAll(this.inputSelectors).forEach((input) => {
      input.removeEventListener("change", this.fillFakeSearchBar.bind(this));
    });

    this.modalSearch.querySelectorAll(this.tabSelectors).forEach((tab) => {
      tab.removeEventListener("click", this.fillFakeSearchBar.bind(this));
    });
  }

  fillFakeSearchBar() {
    var address = document.getElementById("address");
    if (address && address.value) {
      document.getElementById("searchAddress").innerHTML = address.value;
    }

    var shortTerm = document.getElementById("search_show_short_term");
    if (shortTerm && shortTerm.checked) {
      document.getElementById("monthly").classList.add("d-none");
      document.getElementById("hourly").classList.remove("d-none");
    } else {
      document.getElementById("monthly").classList.remove("d-none");
      document.getElementById("hourly").classList.add("d-none");
    }
  }
}
