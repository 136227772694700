import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  initialize() {
    this.element.setAttribute("data-action", "wheel->horizontal-scroll#horizontalScroll");
  }

  horizontalScroll(event) {
    event.preventDefault();
    this.element.scrollBy({
      left: event.deltaY < 0 ? -30 : 30,
    });
  }
}
