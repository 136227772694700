import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["input", "elementToToggle"];

  changed(event) {
    event.preventDefault();
    this.switchElement(event.currentTarget.value);
  }

  switchElement(value) {
    if (value == "company") {
      this.elementToToggleTarget.classList.remove("d-none");
      this.elementToToggleTarget.querySelectorAll("input").forEach((element) => {
        element.required = true;
      });
    } else {
      this.elementToToggleTarget.classList.add("d-none");
      this.elementToToggleTarget.querySelectorAll("input").forEach((element) => {
        element.required = false;
      });
    }
  }
}
