import { Controller } from "@hotwired/stimulus";
import { throttle } from "lodash";

export default class extends Controller {
  static values = {
    source: {
      type: String,
      default: "",
    },
    styleBase: {
      type: String,
      default: "form-control bg-white",
    },
    dropupAuto: {
      type: Boolean,
      default: true,
    },
    mobile: {
      type: Boolean,
      default: false,
    },
  };

  connect() {
    if (this.element.dataset.connected) return;

    this.selectElement = this.element.tagName == "SELECT" ? this.element : this.element.querySelector("select");

    this.selectpicker = $(this.selectElement).selectpicker({
      countSelectedText: "{0} éléments",
      deselectAllText: "Tout désélectionner",
      selectAllText: "Tout sélectionner",
      selectedTextFormat: "count > 2",
      style: "",
      styleBase: this.styleBaseValue,
      selectOnTab: true,
      mobile: this.mobileValue,
    });

    this.element.dataset.connected = true;

    if (this.hasSourceValue) {
      var searchBox = this.element.querySelector(".bs-searchbox input[type='search']");
      if (searchBox) {
        searchBox.setAttribute("data-action", "input->selectpicker#throttledSearch");
        this.throttledSearch = throttle(this.search.bind(this), 300);
      }
    }
  }

  throttledSearch(event) {
    this.throttledSearch(event);
  }

  async search(event) {
    const input = event.target;
    const query = input.value;
    const response = await fetch(`${this.sourceValue}&query=${query}`);
    const newOptions = await response.json();

    this.updateSelectOptions(newOptions);
  }

  onFocus(event) {
    this.selectpicker.selectpicker("show");
  }

  updateSelectOptions(newOptions) {
    const selectedValues = Array.from(this.selectElement.selectedOptions).map((option) => String(option.value));
    Array.from(this.selectElement.options).forEach((option) => {
      if (!selectedValues.includes(option.value)) option.remove();
    });
    newOptions.forEach((option) => {
      if (!selectedValues.includes(String(option.id))) {
        const opt = document.createElement("option");
        opt.value = option.id;
        opt.text = option.name;
        this.selectElement.add(opt);
      }
    });
    this.selectpicker.selectpicker("refresh");
  }
}
