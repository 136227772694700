import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    this.element.setAttribute("data-action", "click->widgets--popover#click");
    this.element.setAttribute("role", "link");
    new bootstrap.Popover(this.element);
  }

  click(e) {
    e.preventDefault();
  }
}
