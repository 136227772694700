import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [
    "spotsCountContainer",
    "spotsCountInput",
    "submitButton",
    "storageErrorMessage",
    "emailInput",
    "shortTermSupportLink",
  ];

  static values = {
    storageError: String,
    parkingId: Number,
  };

  updateSpotsCountContainerDisplay(event) {
    var leadType = event.target.value;

    if (leadType === "professional") {
      this.spotsCountContainerTarget.classList.remove("d-none");
      this.spotsCountInputTarget.required = true;
    } else {
      this.spotsCountContainerTarget.classList.add("d-none");
      this.spotsCountInputTarget.required = false;
      this.spotsCountInputTarget.value = "";
    }
  }

  updateSubmitButtonDisplay(event) {
    var storageType = event.target.value;

    if (storageType === "parking") {
      this.storageErrorMessageTarget.classList.add("d-none");
      this.submitButtonTarget.classList.remove("d-none");

      if (window.ahoy) {
        window.ahoy.track("$click", {
          category: "parking_page",
          action: "ask_question_click_type_parking",
          parking_id: this.parkingIdValue,
          email: this.emailInputTarget.value,
          url: window.location.href,
        });
      }
    } else {
      this.storageErrorMessageTarget.classList.remove("d-none");
      this.submitButtonTarget.classList.add("d-none");

      if (window.ahoy) {
        window.ahoy.track("$click", {
          category: "parking_page",
          action: "ask_question_click_type_storage",
          parking_id: this.parkingIdValue,
          email: this.emailInputTarget.value,
          url: window.location.href,
        });
      }
    }
  }

  updateShortTermSupportLinkDisplay(event) {
    var storageType = event.target.value;

    if (storageType === "short") {
      this.shortTermSupportLinkTarget.classList.remove("d-none");
    } else {
      this.shortTermSupportLinkTarget.classList.add("d-none");
    }
  }
}
