import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = {
    spotTypePrices: Object,
    parkingFullAddress: String,
    parkingId: Number,
    parkingPrice: Number,
    spotTypeId: { type: Number, default: null },
  };

  static targets = [
    "spotType",
    "hiddenFieldQuantity",
    "totalPriceWrapper",
    "totalPriceHt",
    "hiddenFieldDescription",
    "hiddenFieldLongText",
    "hiddenFieldVendorMat",
    "hiddenFieldPrice",
  ];

  initialize() {
    this.formatter = new Intl.NumberFormat("fr-FR", { style: "currency", currency: "EUR" });
    this.compute();
  }

  onCountChanged() {
    this.compute();
  }

  onSpotTypeChanged() {
    this.compute();
  }

  compute() {
    const quantity = this.hiddenFieldQuantityTarget.value;
    const spotTypeChecked = this.spotTypeTargets.find((target) => target.checked);
    const spotTypeId = spotTypeChecked ? spotTypeChecked.value : null;
    const unitPrice = this.spotTypePricesValue ? this.spotTypePricesValue[spotTypeId] : this.parkingPriceValue;
    const totalPrice = unitPrice * quantity;

    // Update hidden field price
    if (unitPrice) {
      this.hiddenFieldPriceTarget.value = unitPrice;
    }

    // Update hidden field description
    if (spotTypeChecked && this.spotTypePricesValue) {
      this.hiddenFieldDescriptionTarget.value = `${spotTypeChecked.labels[0].innerHTML}`;
    } else {
      this.hiddenFieldDescriptionTarget.value = "Place de parking";
    }

    // Update hidden field long text
    if (spotTypeChecked && this.spotTypePricesValue) {
      this.hiddenFieldLongTextTarget.value = `${spotTypeChecked.labels[0].innerHTML} au ${this.parkingFullAddressValue}`;
    } else {
      this.hiddenFieldLongTextTarget.value = `Place de parking au ${this.parkingFullAddressValue}`;
    }

    // Update hidden field vendor mat
    let vendorMatValue = `P${this.parkingIdValue}`;
    if (spotTypeId) {
      vendorMatValue += `ST${spotTypeId}`;
    } else if (this.spotTypeIdValue) {
      vendorMatValue += `ST${this.spotTypeIdValue}`;
    }
    this.hiddenFieldVendorMatTarget.value = vendorMatValue;

    // Display Total under the form
    const formattedTotalPrice = spotTypeId && quantity ? this.formatter.format(totalPrice) : "0,00 €";
    this.totalPriceHtTarget.innerHTML = formattedTotalPrice;
    if (spotTypeId && quantity) {
      this.totalPriceWrapperTarget.classList.remove("d-none");
    } else {
      this.totalPriceWrapperTarget.classList.add("d-none");
    }
  }
}
