import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = {
    imageUrl: String,
    requireLoading: String,
  };

  connect() {
    if (this.imageUrlValue && this.requireLoadingValue === "true") {
      this.element.style.backgroundImage = `url("${this.imageUrlValue}")`;
    }
  }
}
