import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = {
    clientSide: {
      type: Boolean,
      default: false,
    },
    type: String,
    headline: String,
    details: String,
    autocloseDelay: Number,
  };

  static get targets() {
    return ["progress"];
  }

  connect() {
    if (this.clientSideValue != false) this.element.innerHTML = this.clientSideRender();
    if (this.hasAutocloseDelayValue && this.autocloseDelayValue > 0) {
      this.toast = {
        started: false,
        paused: false,
        progress: 0,
        animationFrame: null,
        startedFrameTimestamp: null,
        remainingDurationMs: null,
      };
      this.start();
    }
  }

  clientSideRender() {
    return `
      <div class="ds-toast-progress">
        <div class="ds-toast-progress-bar" data-status="${this.typeValue}" data-toast-target="progress"></div>
      </div>
      <div class="ds-toast-main">
        <div class="ds-toast-text">
          ${this.headlineValue ? `<div class="ds-text-body-md-bold">${this.headlineValue}</div>` : ""}
          ${this.detailsValue ? `<div class="ds-text-body-md">${this.detailsValue}</div>` : ""}
        </div>
        <button class="d-flex align-items-start justify-content-start bg-transparent p-0 border-0 pe-auto" data-action="click->toast#close">&times;</button>
      </div>
    `;
  }

  start() {
    this.toast.started = true;
    this.toast.paused = false;
    this.toast.remainingDurationMs = Math.round(this.autocloseDelayValue * (1 - this.toast.progress));
    this.toast.startedFrameTimestamp = window.performance.now();
    this.toast.animationFrame = window.requestAnimationFrame(this.animate.bind(this));
  }

  pause() {
    if (this.toast && this.toast.started && this.toast.paused == false) {
      this.toast.paused = true;
      if (this.toast.animationFrame) {
        window.cancelAnimationFrame(this.toast.animationFrame);
        this.toast.animationFrame = null;
      }
    }
  }

  resume() {
    if (this.toast && this.toast.started && this.toast.paused == true && this.toast.progress < 1) this.start();
  }

  close() {
    this.element.classList.add("close");
    setTimeout(
      () => {
        this.element.remove();
      },
      parseFloat(getComputedStyle(this.element).transitionDuration, 10) * 1000,
    );
  }

  animate(currentFrameTimestamp) {
    if (!this.toast.paused) {
      const millisecondsElapsed = currentFrameTimestamp - this.toast.startedFrameTimestamp;
      const progress = millisecondsElapsed / this.toast.remainingDurationMs;

      this.toast.progress = Math.max(this.toast.progress, Math.min(progress, 1));
      this.progressTarget.style.setProperty("--toast-progress-value", 1 - this.toast.progress);

      if (this.toast.progress < 1) {
        this.toast.animationFrame = window.requestAnimationFrame(this.animate.bind(this));
      } else {
        this.close();
      }
    }
  }
}
