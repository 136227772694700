import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["header"];
  static values = {
    theme: {
      type: String,
      default: "light",
    },
  };

  connect() {
    this.menuNavUl = document.getElementById("js_user_selection");
    this.scrollNavbarCta = document.getElementById("scroll-navbar-cta");
    this.headerTargetClassName = this.themeValue == "light" ? "fuchsia-navbar" : "bg-dark";

    if (this.hasHeaderTarget) {
      this.nav = document.querySelector(".navbar-nav");
      this.menuIcon = document.getElementById("menu-icon");
      this.crossIcon = document.getElementById("cross-icon");
      this.navLinkMobile = document.getElementById("mobile-search-path");
    }
  }

  toggleMenu() {
    this.headerTarget.classList.toggle(this.headerTargetClassName);
    this.menuNavUl?.classList?.toggle("navbar__subnav--open");
    this.navLinkMobile?.classList?.toggle("d-none");
    this.menuIcon?.classList?.toggle("d-none");
    this.crossIcon?.classList?.toggle("d-none");
  }

  manageClass() {
    if (window.innerWidth < 768) {
      this.headerTarget.classList.remove("transparent-navbar");
      this.headerTarget.classList.remove("ds-box-shadow-down");
    } else if (window.scrollY > 80 && window.innerWidth > 768) {
      this.headerTarget.classList.remove("transparent-navbar");
      this.headerTarget.classList.add("ds-box-shadow-down");
      this.menuNavUl?.classList?.add("d-none");
      this.scrollNavbarCta?.classList?.remove("d-none");
    } else {
      if (["pages_home", "pages_marcel", "yespark_pros_show"].includes(window.page_category)) {
        this.headerTarget.classList.add("transparent-navbar");
      }
      this.menuNavUl?.classList?.remove("d-none");
      this.scrollNavbarCta?.classList?.add("d-none");
      this.headerTarget.classList.remove("ds-box-shadow-down");
    }
  }

  update() {
    if (window.requestAnimationFrame) {
      window.requestAnimationFrame(this.manageClass.bind(this));
    } else {
      setTimeout(this.manageClass.bind(this), 66);
    }
  }

  onScroll(event) {
    this.update();
  }

  onResize(event) {
    this.update();
  }
}
