import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static get targets() {
    return ["input", "placeholder"];
  }

  onChange(event) {
    Array.from(event.currentTarget.files).forEach((file) => this.placeholder(file));
    event.currentTarget.files.value = null;
  }

  placeholder(file) {
    this.placeholderTarget.src = URL.createObjectURL(file);
  }
}
