import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = { enableKeyboard: { type: Boolean, default: false } };

  connect() {
    this.modal = new $.fn.modal.Constructor(this.element, {
      keyboard: this.enableKeyboardValue,
    });
    this.modal.show();
  }

  disconnect() {
    this.modal.hide();
  }
}
