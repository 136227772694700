import { Controller } from "@hotwired/stimulus";
import { debounce } from "lodash";
import GoogleMapsPlacesService from "../../src/shared/google_maps_places_service";

export default class extends Controller {
  static targets = [
    "searchBox",
    "searchBoxResultsItemsWrapper",
    "searchBoxHistoryItemsWrapper",
    "historySvgIcon",
    "addressSvgIcon",
    "form",
    "address",
    "latitude",
    "longitude",
    "queryType",
    "placeTypes",
    "movedMap",
    "swLat",
    "swLng",
    "neLat",
    "neLng",
    "centerPointLat",
    "centerPointLng",
    "city",
    "zipcode",
    "locate",
    "locateButton",
    "clear",
  ];

  static values = {
    googlePlacesApiKey: {
      type: String,
      default: "",
    },
    lengthMinToSearch: {
      type: Number,
      default: 3,
    },
    autofocus: {
      type: Boolean,
      default: false,
    },
    autosubmit: {
      type: Boolean,
      default: false,
    },
    country: {
      type: String,
      default: "fr",
    },
    slug: {
      type: String,
      default: "",
    },
    replaceInputWithSimpleAddress: {
      type: Boolean,
      default: false,
    },
    numberOfRecentSearchesKept: {
      type: Number,
      default: 5,
    },
    alertPleaseSelectFromSuggestionsList: {
      type: String,
      default: "Indiquez un lieu précis : adresse de domicile, gare, aéroport, métro…",
    },
  };

  // Connect lifecycle method to setup component
  connect() {
    window.hangReferenceStimulusController(this);
    this.setupIcons();
    this.initializeGoogleMapsPlacesService();
    this.autoFocusAddressField();
  }

  // Initialization of the Google Maps Places Service
  initializeGoogleMapsPlacesService() {
    this.googleMapsPlacesService = new GoogleMapsPlacesService({
      apiKey: this.googlePlacesApiKeyValue,
      country: this.countryValue,
      language: this.countryValue,
      region: this.countryValue,
    });
    let debounceDuration = 200;
    this.getPlacePredictions = debounce(this.debouncedGetPlacePredictions.bind(this), debounceDuration);
    this.getPlaceDetails = debounce(this.debouncedGetPlaceDetails.bind(this), debounceDuration);
  }

  // Sets up SVG icons
  setupIcons() {
    this.historySvgIcon = this.hasHistorySvgIconTarget ? this.historySvgIconTarget.innerHTML : null;
    this.addressSvgIcon = this.hasAddressSvgIconTarget ? this.addressSvgIconTarget.innerHTML : null;
  }

  // Autofocuses the address field if configured to do so
  autoFocusAddressField() {
    if (this.autofocusValue && this.hasAddressTarget) {
      setTimeout(() => {
        const {
          scrollWidth,
          value: { length },
        } = this.addressTarget;
        this.addressTarget.setSelectionRange(length, length);
        this.addressTarget.scrollLeft = scrollWidth;
        this.addressTarget.focus();
      }, 0);
    }
  }

  /**
   * Event Handlers
   *
   * @onPageClick - Handles click event on page
   * @onAddressClick - Handles click event on address field
   * @onAddressInput - Handles input event on address field
   * @onAddressClear - Handle click event on clear address field
   * @onItemsKeydownArrowDown - Arrow down navigation on the search box items
   * @onItemsKeydownArrowUp - Arrow up navigation on the search box items
   * @onKeydownEnter - Handle enter on a search box item
   * @onGeolocateClick - Handle click event on geolocate
   * @onResultItemClick - Handle click on a search box results item
   * @onHistoryItemClick - Handle click on a search box history item
   */

  onPageClick(e) {
    if (!e.target) return;

    if (!this.searchBoxTarget.contains(e.target) && !this.addressTarget.contains(e.target)) {
      this.hideSearchBox();
    }
  }

  onAddressClick() {
    this.showSearchBox();
    this.dispatch("displayOverlayBackground");
  }

  onAddressInput() {
    this.handleClearAddressIconDisplay();
    this.dispatch("displayOverlayBackground");
    if (this.addressTarget.value && this.addressTarget.value.trim().length > this.lengthMinToSearchValue) {
      this.getPlacePredictions();
    } else {
      this.setSearchResult({
        autocompleteSuggestions: [],
        status: "",
      });
    }
  }

  onAddressClear() {
    this.sendToDataLayerAnalytics("clear");
    this.addressTarget.value = "";
    this.addressTarget.focus();
    this.emitEvent(this.addressTarget, "input");
  }

  onItemsKeydownArrowDown() {
    this.addSelectedCssClassToItem(
      this.resultItemSelected?.nextSibling ||
        (this.searchBoxResultsItemsWrapperTarget.style == "block"
          ? this.searchBoxResultsItemsWrapperTarget.firstChild
          : this.searchBoxHistoryItemsWrapperTarget.firstChild),
    );
  }

  onItemsKeydownArrowUp() {
    this.addSelectedCssClassToItem(
      this.resultItemSelected?.previousSibling ||
        (this.searchBoxResultsItemsWrapperTarget.style == "block"
          ? this.searchBoxResultsItemsWrapperTarget.lastChild
          : this.searchBoxHistoryItemsWrapperTarget.lastChild),
    );
  }

  onKeydownEnter(e) {
    if (this.resultItemSelected == null || typeof this.resultItemSelected === "undefined") {
      window.dispatchEvent(
        new CustomEvent("new-toast", {
          detail: {
            type: "error",
            message: this.alertPleaseSelectFromSuggestionsListValue,
            autocloseDelay: 5000,
          },
          bubbles: true,
        }),
      );
    } else {
      const detailParam = this.resultItemSelected.getAttribute("data-components--address-autocomplete-detail-param");
      if (detailParam != null) {
        this.autofillFieldsFromPlaceData(null, JSON.parse(detailParam));
      } else {
        this.getPlaceDetails();
      }
    }
  }

  onGeolocateClick(e) {
    if (e.currentTarget.disabled) return;

    this.displaySearchOverlaysLoading();

    this.locateButtonTargets.forEach((locateButton) => {
      locateButton.disabled = true;
    });

    this.sendToDataLayerAnalytics("locate");

    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(
        (position) => this.geolocateSuccessCallback(position),
        (error) => this.geolocateErrorCallback(error),
        {
          enableHighAccuracy: true,
          timeout: 5000,
          maximumAge: 0,
        },
      );
    } else {
      this.geolocateErrorCallback("La géolocalisation n'est pas supporté par votre navigateur.");
    }
  }

  onResultItemClick(event) {
    this.addSelectedCssClassToItem(event.currentTarget);
    this.getPlaceDetails();
    this.hideSearchBox();
  }

  onHistoryItemClick(event) {
    this.addSelectedCssClassToItem(event.currentTarget);
    this.autofillFieldsFromPlaceData(null, event.params.detail);
    this.hideSearchBox();
  }

  addSelectedCssClassToItem(itemSelected) {
    if (itemSelected == this.resultItemSelected) return;
    this.resultItemSelected?.classList?.remove(`search-box--item--selected`);
    this.resultItemSelected = itemSelected ? itemSelected : null;
    itemSelected?.classList?.add(`search-box--item--selected`);
  }

  // Fetches place predictions based on input
  debouncedGetPlacePredictions() {
    this.googleMapsPlacesService.getPlacePredictions(
      {
        includeQueryPredictions: false,
        input: this.addressTarget.value.trim(),
      },
      (data) => {
        this.setSearchResult({
          autocompleteSuggestions:
            data.suggestions?.map((suggestion) => {
              return {
                place_id: suggestion.placePrediction.placeId,
                description: suggestion.placePrediction.text.text,
                pretty_description: this.prettyDescription(
                  suggestion.placePrediction.text.text,
                  suggestion.placePrediction.text.matches,
                ),
              };
            }) || [],
          status: data?.error?.status || (data.suggestions?.length ? "OK" : "ZERO_RESULTS"),
        });
      },
    );
  }

  // Fetches place details for the selected item
  debouncedGetPlaceDetails() {
    this.googleMapsPlacesService.getPlaceDetails(
      this.resultItemSelected.dataset.placeId,
      "types,formattedAddress,addressComponents,viewport,location",
      (googleMapsDetailsPlace) => {
        this.autofillFieldsFromPlaceData(googleMapsDetailsPlace, null);
      },
    );
  }

  // Updates the search result state and displays the appropriate UI
  setSearchResult({ autocompleteSuggestions, status }) {
    this.autocompleteSuggestions = autocompleteSuggestions;
    this.status = status;
    this.updateSearchResultsDisplay();
  }

  // Updates the display of search results or errors
  updateSearchResultsDisplay() {
    const resultsItems = [];
    const fragment = document.createDocumentFragment();

    if (this.status === "") {
      this.toggleHistoryDisplay(true);
      this.searchBoxResultsItemsWrapperTarget.style.display = "none";
    } else {
      this.toggleHistoryDisplay(false);
      if (this.status === "OK") {
        resultsItems.push(
          ...this.autocompleteSuggestions.map((suggestion) => this.createSearchBoxResultItem(suggestion)),
        );
        if (resultsItems.length > 0) {
          this.addSelectedCssClassToItem(resultsItems[0]);
        }
      } else {
        resultsItems.push(this.createSearchBoxErrorItem(this.status));
      }
      resultsItems.forEach((item) => fragment.appendChild(item));
      this.searchBoxResultsItemsWrapperTarget.style.display = "block";
    }

    this.searchBoxResultsItemsWrapperTarget.replaceChildren(fragment);
    this.showSearchBox();
  }

  // Toggle display of search history
  toggleHistoryDisplay(show) {
    this.searchBoxHistoryItemsWrapperTarget.style.display = show ? "block" : "none";
  }

  showSearchBox() {
    this.populateRecentSearches();
    this.searchBoxTarget.classList.add("active");
  }

  hideSearchBox() {
    this.searchBoxTarget.classList.remove("active");
  }

  createSearchBoxItem(iconHTML, textHTML, additionalClassNames = []) {
    const item = document.createElement("div");
    item.className = `${["search-box--item", ...additionalClassNames].join(" ")}`;
    item.innerHTML = `<div class="search-box--item--icon">${iconHTML}</div><div class="search-box--item--text">${textHTML}</div>`;
    return item;
  }

  createSearchBoxResultItem(suggestion) {
    const item = this.createSearchBoxItem(this.addressSvgIcon, suggestion.pretty_description);
    item.dataset.description = suggestion.description;
    item.dataset.placeId = suggestion.place_id;
    item.setAttribute("data-event-action", "searchbar-suggestions-address");
    item.setAttribute("data-action", "click->components--address-autocomplete#onResultItemClick");
    return item;
  }

  buildSearchBoxHistoryItem(placeData) {
    const item = this.createSearchBoxItem(this.historySvgIcon, placeData.full_address, ["search-box--item--history"]);
    item.dataset.description = placeData.full_address;
    item.setAttribute("data-event-action", "searchbar-suggestions-recent-address-typed");
    item.setAttribute("data-action", "click->components--address-autocomplete#onHistoryItemClick");
    item.setAttribute("data-components--address-autocomplete-detail-param", JSON.stringify(placeData));
    return item;
  }

  createSearchBoxErrorItem(status) {
    let textHTML = "";

    switch (status) {
      case "ZERO_RESULTS":
      case "NOT_FOUND":
        textHTML = "Aucun lieu n'est trouvé. veuillez réessayer avec d'autres termes de recherche.";
        break;
      case "OVER_QUERY_LIMIT":
        textHTML = "Yespark a rencontré un problème technique, veuillez réessayer dans quelques minutes.";
        break;
      case "INVALID_REQUEST":
      case "REQUEST_DENIED":
        textHTML =
          "Yespark a rencontré un problème technique, veuillez contacter le service client https://support.yespark.com/hc/fr/requests/new";
        break;
      case "UNKNOWN_ERROR":
        textHTML =
          "Nous éprouvons des problèmes techniques et nous travaillons activement à les résoudre. Veuillez réessayer dans quelques minutes.";
        break;
      default:
        textHTML =
          "Nous éprouvons des problèmes techniques et nous travaillons activement à les résoudre. Veuillez réessayer dans quelques minutes.";
    }

    const item = this.createSearchBoxItem(this.addressSvgIcon, textHTML, ["search-box--item--error"]);
    item.role = "alert";
    return item;
  }

  prettyDescription(description, matches) {
    if (!matches || matches.length === 0) {
      return description;
    }

    matches.sort((a, b) => (a.startOffset || 0) - (b.startOffset || 0));

    let lastIndex = 0;
    const parts = [];

    matches.forEach(({ startOffset = 0, endOffset }) => {
      if (startOffset >= 0 && endOffset <= description.length) {
        parts.push(description.slice(lastIndex, startOffset));
        parts.push(`<b>${description.slice(startOffset, endOffset)}</b>`);
        lastIndex = endOffset;
      }
    });

    parts.push(description.slice(lastIndex));

    return parts.join("");
  }

  handleClearAddressIconDisplay() {
    if (this.hasClearTarget) {
      if (this.addressTarget.value && this.addressTarget.value.length > 0) {
        this.clearTarget.style.visibility = "visible";
      } else {
        this.clearTarget.style.visibility = "hidden";
      }
    }
  }

  autofillFieldsFromPlaceData(googleMapsDetailsPlace, placeData) {
    if (this.autosubmitValue) {
      this.displaySearchOverlaysLoading();
    }

    placeData = placeData || this.buildPlaceDataFromGoogleMapsPlacesServiceGetDetailsResponse(googleMapsDetailsPlace);

    if (placeData.location) {
      this.resetSearchFormBoundsFields();
      this.setLatLng(placeData.location.latitude, placeData.location.longitude);
    } else if (placeData.viewport) {
      if (this.hasSwLatTarget) this.swLatTarget.value = placeData.viewport.south_west_lat;
      if (this.hasSwLngTarget) this.swLngTarget.value = placeData.viewport.south_west_lng;
      if (this.hasNeLatTarget) this.neLatTarget.value = placeData.viewport.north_east_lat;
      if (this.hasNeLngTarget) this.neLngTarget.value = placeData.viewport.north_east_lng;
      if (this.hasCenterPointLatTarget) this.centerPointLatTarget.value = placeData.viewport.center_lat;
      if (this.hasCenterPointLngTarget) this.centerPointLngTarget.value = placeData.viewport.center_lng;
      this.setLatLng("", "");
    } else {
      this.resetSearchFormBoundsFields();
      this.setLatLng("", "");
    }

    if (placeData.full_address && placeData.full_address.length > 0) {
      this.sendToDataLayerAnalytics("search", placeData.full_address);
      this.saveToRecentSearches(placeData);
    }

    if (this.hasAddressTarget) {
      this.addressTarget.value = this.replaceInputWithSimpleAddressValue ? placeData.address : placeData.full_address;
      this.emitEvent(this.addressTarget, "change");
    }
    if (this.hasCityTarget) {
      this.cityTarget.value = placeData.city;
      this.emitEvent(this.cityTarget, "change");
    }
    if (this.hasZipcodeTarget) {
      this.zipcodeTarget.value = placeData.zipcode;
      this.emitEvent(this.zipcodeTarget, "change");
    }
    if (this.hasQueryTypeTarget) {
      this.queryTypeTarget.value = placeData.query_type;
      this.emitEvent(this.queryTypeTarget, "change");
    }

    if (this.hasMovedMapTarget) {
      this.movedMapTarget.value = false;
      this.emitEvent(this.movedMapTarget, "change");
    }

    if (this.hasPlaceTypesTarget) {
      if (placeData.place_types && placeData.place_types.length > 0) {
        this.placeTypesTarget.value = placeData.place_types.sort().join(",");
      } else {
        this.placeTypesTarget.value = "";
      }
      this.emitEvent(this.placeTypesTarget, "change");
    }

    if (this.autosubmitValue) {
      this.submitForm();
    } else {
      this.displayNextInput();
    }
  }

  // Send event to dispatch change on the field
  emitEvent(element, eventName) {
    const event = new Event(eventName, { bubbles: true });
    element.dispatchEvent(event);
  }

  // History management
  getHistoryLocalStorageKey() {
    if (!this.historyLocalStorageKey)
      this.historyLocalStorageKey = `yespark.search.history.${window.location.hostname}`;
    return this.historyLocalStorageKey;
  }

  fetchRecentSearches() {
    const storedPlacesData = localStorage.getItem(this.getHistoryLocalStorageKey());
    return storedPlacesData ? JSON.parse(storedPlacesData) : [];
  }

  populateRecentSearches() {
    const recentSearches = this.fetchRecentSearches();
    const fragment = document.createDocumentFragment();
    recentSearches.forEach((placeData) => {
      const item = this.buildSearchBoxHistoryItem(placeData);
      fragment.appendChild(item);
    });

    this.searchBoxHistoryItemsWrapperTarget.innerHTML = "";
    this.searchBoxHistoryItemsWrapperTarget.appendChild(fragment);
  }

  saveToRecentSearches(placeData) {
    const recentSearches = this.fetchRecentSearches();
    if (!recentSearches.some((search) => search.full_address === placeData.full_address)) {
      recentSearches.unshift(placeData);
      if (recentSearches.length > this.numberOfRecentSearchesKeptValue) {
        recentSearches.length = this.numberOfRecentSearchesKeptValue;
      }
      const fragment = document.createDocumentFragment();
      fragment.appendChild(this.buildSearchBoxHistoryItem(placeData));
      this.searchBoxHistoryItemsWrapperTarget.insertBefore(
        fragment,
        this.searchBoxHistoryItemsWrapperTarget.firstChild,
      );
      localStorage.setItem(this.getHistoryLocalStorageKey(), JSON.stringify(recentSearches));
    }
  }

  // Analytics
  sendToDataLayerAnalytics(action, label) {
    if (dataLayer !== undefined) {
      dataLayer.push({
        event: "eventGA",
        event_category: `app pages ${this.slugValue}`.trim(),
        event_action: action,
        event_label: label ?? null,
      });
    }
  }

  buildPlaceDataFromGoogleMapsPlacesServiceGetDetailsResponse(place) {
    let placeData = {
      full_address: "",
      address: "",
      city: null,
      zipcode: null,
      query_type: null,
      location: null,
      viewport: null,
      placeData: [],
    };

    if (place) {
      placeData.full_address = place.formattedAddress;
    }

    if (placeData.full_address == null || placeData.full_address == "") {
      placeData.full_address = this.addressTarget.value.trim();
    }

    if (place) {
      if (place.location) {
        placeData.location = place.location;
      }

      if (place.viewport) {
        placeData.viewport ||= {};

        var southWestCoords = place.viewport.low;
        placeData.viewport.south_west_lat = southWestCoords.latitude;
        placeData.viewport.south_west_lng = southWestCoords.longitude;

        var northEastCoords = place.viewport.high;
        placeData.viewport.north_east_lat = northEastCoords.latitude;
        placeData.viewport.north_east_lng = northEastCoords.longitude;

        if (placeData.location) {
          placeData.viewport.center_lat = placeData.location.latitude;
          placeData.viewport.center_lng = placeData.location.longitude;
        }
      }

      if (typeof place.addressComponents !== "undefined") {
        var queryTypesFound = [];
        for (let i = 0; i < place.addressComponents.length; i++) {
          const allTypes = place.addressComponents[i].types;
          const addressType = allTypes[0];
          const component = place.addressComponents[i];

          if (allTypes.includes("route")) {
            queryTypesFound.push("address");
          } else if (allTypes.includes("sublocality")) {
            queryTypesFound.push("suburb");
          } else if (allTypes.includes("locality")) {
            queryTypesFound.push("city");
          } else if (allTypes.includes("administrative_area_level_2")) {
            queryTypesFound.push("department");
          } else if (allTypes.includes("administrative_area_level_1")) {
            queryTypesFound.push("region");
          } else if (allTypes.includes("country")) {
            queryTypesFound.push("country");
          }

          switch (addressType) {
            case "street_number":
              placeData.address += component.shortText + " ";
              break;
            case "route":
              placeData.address += component.shortText;
              break;
            case "sublocality":
              break;
            case "locality":
              placeData.city = component.shortText || component.longText;
              break;
            case "postal_code":
              placeData.zipcode = component.shortText;
              break;
          }
        }
      }

      const queryTypesPriorities = ["address", "suburb", "city", "department", "region", "country"];
      let priorityQueryType = queryTypesPriorities.find((type) => queryTypesFound.includes(type)) || "address";
      placeData.query_type = priorityQueryType;

      if (place.types) {
        placeData.place_types = place.types;
        if (
          placeData.query_type != "address" &&
          (place.types.includes("subway_station") ||
            place.types.includes("transit_station") ||
            place.types.includes("point_of_interest") ||
            place.types.includes("establishment"))
        ) {
          placeData.query_type = "address";
        }
        if (place.types.includes("point_of_interest")) {
          var poiNameParts = null;
          if (
            this.resultItemSelected &&
            this.resultItemSelected.dataset.description &&
            this.resultItemSelected.dataset.description != ""
          ) {
            poiNameParts = this.resultItemSelected.dataset.description.split(",");
          }
          if (poiNameParts) {
            if (placeData.full_address == "") {
              placeData.full_address = poiNameParts.join(", ");
            } else if (!placeData.full_address.includes(poiNameParts[0])) {
              placeData.full_address = `${[poiNameParts[0], placeData.full_address].join(", ")}`;
            }
          }
        }
      }
    }

    return placeData;
  }

  submitForm(event = null) {
    if (this.hasFormTarget) {
      if (event) {
        event.preventDefault();
      }
      if (this.formTarget.dataset && this.formTarget.dataset.turbo) {
        this.formTarget.requestSubmit();
      } else {
        this.formTarget.submit();
      }
    }
  }

  setLatLng(lat, lng) {
    if (this.hasLatitudeTarget) {
      this.latitudeTarget.value = lat;
      this.emitEvent(this.latitudeTarget, "change");
    }
    if (this.hasLongitudeTarget) {
      this.longitudeTarget.value = lng;
      this.emitEvent(this.longitudeTarget, "change");
    }
  }

  resetSearchFormBoundsFields() {
    if (this.hasSwLatTarget) {
      this.swLatTarget.value = "";
      this.emitEvent(this.swLatTarget, "change");
    }
    if (this.hasSwLngTarget) {
      this.swLngTarget.value = "";
      this.emitEvent(this.swLngTarget, "change");
    }
    if (this.hasNeLatTarget) {
      this.neLatTarget.value = "";
      this.emitEvent(this.neLatTarget, "change");
    }
    if (this.hasNeLngTarget) {
      this.neLngTarget.value = "";
      this.emitEvent(this.neLngTarget, "change");
    }
    if (this.hasCenterPointLatTarget) {
      this.centerPointLatTarget.value = "";
      this.emitEvent(this.centerPointLatTarget, "change");
    }
    if (this.hasCenterPointLngTarget) {
      this.centerPointLngTarget.value = "";
      this.emitEvent(this.centerPointLngTarget, "change");
    }
  }

  displaySearchOverlaysLoading() {
    var searchOverlaysLoading = document.querySelectorAll(".search-overlay-loading");
    if (searchOverlaysLoading) {
      searchOverlaysLoading.forEach((searchOverlayLoading) => searchOverlayLoading.classList.add("active"));
    }
  }

  hideSearchOverlaysLoading() {
    var searchOverlaysLoading = document.querySelectorAll(".search-overlay-loading");
    if (searchOverlaysLoading) {
      searchOverlaysLoading.forEach((searchOverlayLoading) => searchOverlayLoading.classList.remove("active"));
    }
  }

  displayNextInput() {
    this.hideSearchBox();
    window.dispatchEvent(new CustomEvent("display-next-input", { bubbles: true }));
  }

  geolocateSuccessCallback(position) {
    this.locateButtonTargets.forEach((locateButton) => {
      locateButton.disabled = false;
    });

    if (this.autosubmitValue) {
      this.displaySearchOverlaysLoading();
    } else {
      this.hideSearchOverlaysLoading();
    }

    this.resetSearchFormBoundsFields();
    this.setLatLng(position.coords.latitude, position.coords.longitude);

    this.addressTarget.value = "";
    this.emitEvent(this.addressTarget, "change");
    if (this.hasLocateTarget) {
      this.locateTarget.value = true;
      this.emitEvent(this.locateTarget, "change");
    }

    if (this.autosubmitValue) {
      this.submitForm();
    } else {
      this.displayNextInput();
    }
  }

  geolocateErrorCallback(error) {
    this.hideSearchOverlaysLoading();

    this.locateButtonTargets.forEach((locateButton) => {
      locateButton.disabled = false;
    });

    const message = typeof error === "object" && typeof error.message === "string" ? error.message : error;

    window.dispatchEvent(
      new CustomEvent("new-toast", {
        detail: {
          type: "error",
          message: message,
          autocloseDelay: 5000,
        },
        bubbles: true,
      }),
    );
  }
}
