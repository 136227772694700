import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static get targets() {
    return ["address", "email", "submit"];
  }

  connect() {
    if (true == false && this.hasAddressTarget && this.addressTarget.value.length == 0) {
      setTimeout(() => {
        const {
          scrollWidth,
          value: { length },
        } = this.addressTarget;
        this.addressTarget.setSelectionRange(length, length);
        this.addressTarget.scrollLeft = scrollWidth;
        this.addressTarget.focus();
      }, 0);
    } else if (true == false && this.hasEmailTarget && this.emailTarget.value.length == 0) {
      setTimeout(() => {
        const {
          scrollWidth,
          value: { length },
        } = this.emailTarget;
        this.emailTarget.setSelectionRange(length, length);
        this.emailTarget.scrollLeft = scrollWidth;
        this.emailTarget.focus();
      }, 0);
    } else if (this.hasSubmitTarget) {
      setTimeout(() => {
        this.submitTarget.focus();
      }, 0);
    }
  }
}
