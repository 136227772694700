import { Controller } from "@hotwired/stimulus";
import * as Hammer from "hammerjs";

// https://dimsemenov.com/plugins/magnific-popup/documentation.html#overflowy
export default class extends Controller {
  static targets = ["element"];
  static values = {
    items: {
      type: Array,
      default: [],
    },
  };

  connect() {
    this.setupMagnificPopup();
  }

  setupMagnificPopup() {
    if (this.hasElementTarget) {
      $(this.element).magnificPopup(this.optionsMagnificPopup());
    }
  }

  optionsMagnificPopup() {
    const options = {
      type: "image",
      gallery: {
        enabled: true,
        navigateByImgClick: true,
        tPrev: "Précédent (Flèche de gauche)",
        tNext: "Suivant (Flèche de droite)",
        tCounter: "%curr% sur %total%",
        arrowMarkup: '<button title="%title%" type="button" class="mfp-arrow mfp-arrow-%dir%"></button>',
      },
      index: 0,
      modal: false,
      showCloseBtn: true,
      enableEscapeKey: true,
      closeMarkup: '<button title="%title%" type="button" class="mfp-close">&#215;</button>',
      tClose: "Fermer (Echap)",
      tLoading: "Chargement...",
      image: {
        tError: "<a href=\"%url%\">L'image</a> n'a pas pu se charger.",
      },
      ajax: {
        tError: '<a href="%url%">La requêta</a> a échoué.',
      },
      mainClass: "mfp-with-zoom",
      removalDelay: 300,
      callbacks: {
        open: function () {
          var mc = new Hammer.default(document.body);
          mc.on("swipeleft", function () {
            document.querySelectorAll(".mfp-arrow-left").forEach((arrow) => {
              arrow.magnificPopup("prev");
            });
          });
          mc.on("swiperight", function () {
            document.querySelectorAll(".mfp-arrow-right").forEach((arrow) => {
              arrow.magnificPopup("next");
            });
          });
        },
      },
    };
    if (this.itemsValue && this.itemsValue.length > 0) {
      options.items = this.itemsValue;
    } else {
      options.delegate = "a";
    }
    return options;
  }

  showGallery(event) {
    event.preventDefault();
    $(this.element).magnificPopup(this.optionsMagnificPopup());
  }
}
