import { Controller } from "@hotwired/stimulus";
import { useMutation } from "stimulus-use";
import Pagy from "../src/gems/pagy/pagy.mjs";

export default class extends Controller {
  connect() {
    this.mutationObserver = useMutation(this, { childList: true, subtree: true });
    Pagy.init(this.element);
  }

  mutate(entries) {
    if (this.shouldHandleMutation(entries)) {
      if (this.mutationObserver && this.mutationObserver[1]) {
        this.mutationObserver[1]();
        this.element.innerHTML = this.element.innerHTML
          .replace(/<ul class="pagination">/g, '<ul class="pagination pagination-sm">')
          .replace("pagy-bootstrap nav", "pagy-bootstrap mt-1 nav");
      }
    }
  }

  shouldHandleMutation(entries) {
    return entries.some((entry) =>
      Array.from(entry.addedNodes).some((node) => node.nodeType === Node.ELEMENT_NODE && node.matches("ul.pagination")),
    );
  }
}
