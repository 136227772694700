import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    const myModal = document.getElementById("yesparkProConfirmationModal");
    if (myModal) {
      bootstrap.Modal.getOrCreateInstance(myModal).show();
    }
  }
}
