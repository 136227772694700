import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["container"];

  unsetHeight(event) {
    event.preventDefault();
    this.containerTarget.style.maxHeight = "unset";
  }
}
